import {
    RainbowKitProvider,
    AvatarComponent,
} from '@rainbow-me/rainbowkit';
  import { HEADER } from 'src/constants/images';
  
  const CustomAvatar: AvatarComponent = ({ address, ensImage, size }) => {
    return (
        <img
        src={HEADER.LOGO}
        width={size}
        height={size}
        style={{ borderRadius: 999 }}
          />
    )
};
  
export default CustomAvatar;

