import { Modal } from "antd";
import { Box } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import { HEADER } from "../../../constants/images";
import { SlidePuzzle } from "../../../components/atoms/SlidePuzzle";
import { DragPuzzle } from "../../../components/atoms/DragPuzzle";


interface Props {
  isPuzzleModalOpen: boolean;
  handlePuzzleModalCancel: () => void;
  activeJourney: null | number;
  handlePuzzleModalBack: () => void;
  handleStakeModal: () => void;
  journeys: any[];
  isModalOpen: boolean;
  setOpenPuzzleAnswer: (state: boolean) => void;
}

export const PuzzleModal: React.FC<Props> = ({
  isPuzzleModalOpen,
  handlePuzzleModalCancel,
  activeJourney,
  handlePuzzleModalBack,
  handleStakeModal,
  journeys,
  isModalOpen,
  setOpenPuzzleAnswer
}) => {

  return (
    <Modal
      centered
      open={isPuzzleModalOpen}
      onCancel={handlePuzzleModalCancel}
      footer={null}
      className={`modal-container ${activeJourney && journeys[activeJourney - 1]?.type === "shadow"
          ? "shadow-modal-container"
          : ""
        }`}
      width={900}
    // afterClose={() => {
    //   if (
    //     activeJourney &&
    //     journeys[activeJourney - 1]?.mediaType2 === "video"
    //   ) {
    //     const iframe = document.querySelector("iframe") as any;
    //     iframe.src = `${journeys[activeJourney - 1]?.link2}`;
    //   }
    // }}
    >
      {activeJourney && journeys[activeJourney - 1]?.type === "shadow" && (
        <div className="modal-icon">
          <img src={HEADER.SHADOWLOGO} width="100px" />
        </div>
      )}
      <div className="modal-text">
        <div
          className={`modal-title ${activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-modal-title"
              : ""
            }`}
        >
          {activeJourney &&
            `${activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "Chapter 2,"
              : ""
            } Journey #${journeys[activeJourney - 1]?.number}: ${journeys[activeJourney - 1]?.title
            }`}
        </div>
        {activeJourney &&
          journeys[activeJourney - 1]?.puzzleCopy.map(
            (copy: string, index: number, array: string[]) => {
              return (
                <div key={index}>
                  <div
                    className={`modal-subtitle ${journeys[activeJourney - 1]?.type === "shadow"
                        ? "shadow-modal-subtitle"
                        : ""
                      }`}
                  >
                    {copy}
                  </div>
                  {index !== array.length - 1 && <br />}
                </div>
              );
            }
          )}
      </div>
      {/* <DeskPuzzle /> */}
      {/* <Box display="flex" justifyContent="center" gap={4} mb={4}>
        <Zoom>
          <img src={JOURNEY7.JOURNEYCOMICDIFFERENT} width="839px" />
        </Zoom>
      </Box> */}
      {/* <DragPuzzle setOpenPuzzleAnswer={setOpenPuzzleAnswer}/> */}
      {/* <SlidePuzzle setOpenPuzzleAnswer={setOpenPuzzleAnswer}/> */}
      <div>
        {activeJourney &&
          (journeys[activeJourney - 1]?.mediaType2 === "image" ? (
            <Zoom>
              <img src={journeys[activeJourney - 1]?.link2} width="839px" />
            </Zoom>
          ) : (
            isModalOpen && (
              <iframe
                width="560"
                height="315"
                src={`${journeys[activeJourney - 1]?.link2}`}
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )
          ))}
      </div>
      {/* <div
        className={`print-link ${activeJourney && journeys[activeJourney - 1]?.type === "shadow"
            ? "shadow-print-link"
            : ""
          }`}
        onClick={() =>
          window.open(
            "https://content.coolcatsnft.com/wp-content/uploads/2023/07/Journey17Puzzle_PrinterFriendly.pdf",
            "_blank" // <- This is what makes it open in a new window.
          )
        }
      >
        Printer Friendly Version
      </div> */}

      <div className="journey-btn-group">
        <div
          className="journey-btn btn-cancel btn-slice-left"
          onClick={handlePuzzleModalBack}
        >
          Back
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={`puzzle-note ${activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-puzzle-note"
              : ""
            }`}>
            Need Help? Join our{" "}
            <span
              className={`print-link ${activeJourney && journeys[activeJourney - 1]?.type === "shadow"
                  ? "shadow-print-link"
                  : ""
                }`}
              onClick={() =>
                window.open(
                  "https://discord.com/invite/coolcatsnft",
                  "_blank" // <- This is what makes it open in a new window.
                )
              }
            >
              DISCORD
            </span>{" "}
            and ask!
          </div>
          {/* <div className="puzzle-note">
            Hint: Color coding important symbols can be helpful
            <span
                          className="print-link"
                          onClick={() =>
                            window.open(
                              "https://world.coolcatsnft.com/cooltopia-the-world/contents",
                              "_blank" // <- This is what makes it open in a new window.
                            )
                          }
                        >
                          LORE
                        </span>
          </div> */}
        </div>

        <button
          className="journey-btn btn-slice-right"
          onClick={handleStakeModal}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};
