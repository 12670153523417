import { useContext, useEffect, useState } from "react";
import { limitCharacter } from "../../../helpers/limitCharacter";
import styled from "styled-components";
import { HOMEPAGE } from "../../../constants/images";
import { CoolScore, JourneyScore } from "../../molecules/Score";
import { useAccount } from "wagmi";
import { imageAPIURL } from "src/constants";
import { IconButton } from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { ScoreContext } from "src/hoc/ScoreProvider";
import journeys from "src/constants/journeys";
import { isJourneyActive } from "src/helpers/journey";

export default function Reward() {
  const { address } = useAccount();
  // const address = "0xd9463b55af194d4fa22a532a52f5bdd45b66d290";
  // const address = "0xce188753344ed4d8b8570be9abbea5006e4fad64";

  const { coolScore, journeyScore } = useContext(ScoreContext);
  const [activeJourney, setActiveJourney] = useState(1);
  const [journeyReward, setJourneyReward] = useState<any[]>([]);

    //Find currently active journey based on users participation else active date period
    const getActiveJourney = (address: any) => {
      for (const journey of journeys) {
        if (!journey?.reward?.hasOwnProperty('data')) {
          setActiveJourney(journey.number - 1);
          break;
        }
      }
    };

  function ImageWithPlaceholder(props: any) {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
      const image = new Image();
      image.src = props.src;
      image.onload = () => {
        setImageSrc(props.src);
      };
    }, [props.src]);

    return (
      <img
        src={imageSrc || HOMEPAGE.NFTPLACEHOLDER}
        alt="img"
        className={props.className}
        onClick={props.onClick}
        style={{
          borderRadius: "25px",
          cursor: "pointer",
          width: "150px",
          height: "150px",
          margin: "auto",
          display: "flex",
          marginBottom: "10px",
        }}
      />
    );
  }

  useEffect(() => {
    if (address) {
      getActiveJourney(address);
    }
  }, [address]);

  useEffect(() => {
    setJourneyReward(journeys[activeJourney - 1]?.reward?.data ?? []);
  }, [activeJourney]);

  return (
    <RewardContainer>
      <div className="sidebar-img-container">
        <div className={`sidebar`}>
          {" "}
          <>
            <div className="score-group">
              <CoolScore score={coolScore} address={address} />
              <JourneyScore score={journeyScore} address={address} />
            </div>
            {journeyReward.filter(
              (item) => item.winner.toLowerCase() === address?.toLowerCase()
            ).length !== 0 && (
              <div className="sidebar-journey-info">
                <div className="journey-info-text">
                  <div className="journey-title">YOU WON!!</div>
                  <div className="journey-ends">See Prize List for Details</div>
                </div>
              </div>
            )}
            <div
              className="profile-group"
              onClick={() =>
                (window.location.href = `https://coolcatsnft.com/user/${address}`)
              }
            >
              <img src={HOMEPAGE.USERICON} />
              <span className="profile-text">Your Profile</span>
            </div>
          </>
        </div>
        <div className="rewardContainer">
          <div className="confirm-header">JOURNEY REWARDS</div>
          <div className="confirm-description">
            We will be randomly selecting some of those that were brave enough
            to embark on the journey some unique rewards. See the list of
            rewards, and the winners for each Journey below.{" "}
          </div>

          <div className="journey-slider">
            <IconButton
              className="arrow-icon"
              disabled={activeJourney === 1}
              onClick={() => {
                if (activeJourney !== 1) {
                  setActiveJourney(activeJourney - 1);
                }
              }}
            >
              <WestIcon />
            </IconButton>
            <div className="slider-header">JOURNEY #{activeJourney}</div>
            <IconButton
              className="arrow-icon"
              disabled = {!journeys[activeJourney]?.reward?.hasOwnProperty('data')}
              onClick={() => {
                if (journeys[activeJourney]?.reward?.hasOwnProperty('data')) {
                  setActiveJourney(activeJourney + 1);
                }
              }}
            >
              <EastIcon />
            </IconButton>
          </div>
          <div className="confirm-description">
            Selection Hash:{"   "}
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  journeys[activeJourney - 1]?.reward?.selectionLink || "#",
                  "_blank" // <- This is what makes it open in a new window.
                )
              }
            >
              {limitCharacter(
                journeys[activeJourney - 1]?.reward?.selectionHash || "",
                5,
                true
              )}
            </span>
          </div>
          {journeyReward.length !== 0 ? (
            <div className="reward-box-container">
              {journeyReward.map((item) => {
                return (
                  <div className="reward-box">
                    <div className="reward-box-title">
                      {item.token_type === "FRAC" ? "FRACTURE" : "COOL CAT"} #
                      {item && item["token_id"]}
                    </div>
                    <ImageWithPlaceholder
                      src={
                        item.token_type === "FRAC"
                          ? `https://avatar.coolcatsnft.com/fracture/${item.token_id}.png`
                          : `https://avatar.coolcatsnft.com/cat/${item.token_id}.png`
                      }
                    />
                    <div className="confirm-description">
                      {limitCharacter(item.winner || "", 5, true)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-rewards reward-box-container">No Rewards Found</div>
          )}
        </div>
      </div>
    </RewardContainer>
  );
}

const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  .reward-box-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .no-rewards {
    display: flex;
    justify-content: center;
    color: #ffffff;
  }

  .reward-box {
    width: 300px;
    height: 250px;

    background: rgba(0, 0, 0, 0.61);
    border-radius: 20px;
    padding: 10px;
  }

  .reward-box-title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #ffffff;
  }

  .journey-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .slider-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 56px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    padding-top: 5px;
    color: #ffffff;
  }

  .arrow-icon {
    background: #0094e3;
    width: 70px;
    height: 40px;
    border-radius: 35px;
  }

  .arrow-icon:hover {
    background: #0094e3;
  }

  .arrow-icon:disabled {
    background: #0094e3;
    opacity: 0.3;
  }

  .rewardContainer {
    background: #231f20;
    padding: 50px;
    width: 100%;
  }

  .confirm-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 12px;
    color: #ffffff;
  }

  .confirm-description {
    width: 50%;
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;

    color: #ffffff;

    margin-bottom: 28px;

    & span {
      text-decoration: underline;
    }
  }

  .sidebar-img-container {
    display: flex;
    width: 100%;
  }

  .sidebar {
    width: 350px !important;
    background: #353233;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 40px;
    padding-top: 40px;
    position: relative;
    margin-left: 0px;
    transition: margin-left 0.3s;
  }

  .sidebar-journey-info {
    width: 275px;
    padding: 20px;
    background: #231f20;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .score-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .profile-group {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }

  .profile-text {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #0094e3;
    padding-top: 5px;
  }

  .sidebar-connect-message {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 132.1%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #ffffff;
  }

  .alignCenter {
    justify-content: center;
  }

  .sidebar-button {
    background: #2e2b2c;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: absolute;
    right: -20px;
    z-index: 1;
    cursor: pointer;
  }

  .sidebar-button-icon {
    color: white;
  }

  .sidebar-close {
    margin-left: -350px;
  }

  .mob-sidebar {
    width: 100%;
    padding-bottom: 70px;
  }

  .journey-info {
    width: 275px;
    height: 245px;
    padding: 30px;
    background: #231f20;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .journey-info-text {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 147.6%;
    /* or 30px */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
  }

  .journey-title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #eb515d;
  }

  .journey-ends {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`;
