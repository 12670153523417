import { GlobalStyles } from "./styles/global-styles";
import Routing from "./routes";
import "antd/dist/antd.min.css";
import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";

console.log = function () {};

function App() {
  return process.env.REACT_APP_API_URL === "https://dev.journey.coolcatsnft.com/v1/" ? (
    <Protect sha512="8eefac9ff7f9d89064dd7b86e0e20579dfe448296b679ce4f84302843063eba8273c36661743f79702857b369b454dba155c948193c081fb808062c68871af30">
      <GlobalStyles />
      <Routing />
    </Protect>
  ) : (
    <>
      <GlobalStyles />
      <Routing />
    </>
  );
}

export default App;
