import PublicSaleContract from "../contracts/CCOFPublicsale.json";
import InventoryContract from "../contracts/CCOFInventory.json";
import MinterContract from "../contracts/MintContract.json"

export interface IChainConfigs {
    [key: string]: IBlockchainInfo;
}

export enum ContractType {
    MINT = "mint"
}

export interface IBlockchainInfo {
    name: string;
    symbol: string;
    shortName: string;
    icon?: { black: string; white: string; color: string };
    logoUrl?: string;
    explorerUrl?: string;
    rpcUrls: string[];
    chainId: string;
}

export const chainConfigs: IChainConfigs = {
    mainnet: {
        name: "Ethereum Mainnet",
        rpcUrls: [
            "https://mainnet.infura.io/v3/0aab62a52a9a4340b584578c39a5a4a3",
        ],
        chainId: "0x1",
        symbol: "ETH",
        shortName: "Ethereum",
        explorerUrl: "https://etherscan.io/",
    },
    // devnet: {
    //   name: "Goerli Test Network",
    //   rpcUrls: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    //   chainId: "0x5",
    //   symbol: "gETH",
    //   shortName: "Goerli",
    //   explorerUrl: "https://goerli.etherscan.io/",
    // },

    testnet: {
        name: "Mumbai Test Network",
        rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
        chainId: "0x13881",
        symbol: "MATIC",
        shortName: "MUMBAI",
        explorerUrl: "https://polygonscan.com/",
    },
    polygon: {
        name: "Polygon Network",
        rpcUrls: ["https://polygon-mainnet.g.alchemy.com/v2/xdHq_sA4knWj9pm78byxSG7-LK17Rbft"],
        chainId: "0x137",
        symbol: "MATIC",
        shortName: "POLYGON",
        explorerUrl: "https://polygonscan.com/",
    },

    devnet: {
        name: "Goerli Test Network",
        rpcUrls: [
            "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        ],
        chainId: "0x5",
        symbol: "gETH",
        shortName: "Goerli",
        explorerUrl: "https://goerli.etherscan.io/",
    },
};

export const getChainInfo = () => {
    return chainConfigs[process.env.REACT_APP_CHAIN || "testnet"];
};

export const contractConfigs = {
    mint: {
        address:
            process.env.REACT_APP_MINT_CONTRACT_ADDRESS ||
            "0x7EB9C8E73a35051dFe8c2FceF22e08604851f55D",
        abi: MinterContract,
    }
};

export const getContractAddress = (type: ContractType) => {
    return contractConfigs[type] || contractConfigs["mint"];
};
