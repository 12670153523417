import moment from "moment-timezone";
import { formattedDate } from "src/helpers/formatters";

export const isJourneyLocked = (startDateString: string) => {
    const now = moment();
    return now.isBefore(
      formattedDate(startDateString)
    );
};
  
export const isJourneyCompleted = (endDateString: string) => {
    const now = moment();
    return now.isAfter(
      formattedDate(endDateString)
    );
  };

export const isJourneyActive = (startDateString: string, endDateString: string) => {
    const now = moment();
    return now.isBetween(
      formattedDate(startDateString),
      formattedDate(endDateString)
    );
  };
  
  export const getCountdownDate = (
    startDateString: string,
    stakingCloseDateString: string,
    endDateString: string
  ) => {
    const now = moment();
    if (
      now.isAfter(formattedDate(startDateString)) &&
      now.isBefore(formattedDate(stakingCloseDateString))
    ) {
      return {
        text: "Staking Closes",
        date: formattedDate(stakingCloseDateString),
      };
    }
    if (
      now.isAfter(formattedDate(stakingCloseDateString)) &&
      now.isBefore(formattedDate(endDateString))
    ) {
      return { text: "Journey Ends", date: formattedDate(endDateString) };
    }
  
    if (now.isAfter(formattedDate(endDateString))) {
      return { text: "Journey Complete", date: formattedDate(endDateString) };
    }
    return { text: "Journey begins", date: formattedDate(startDateString) };
  };