import { Col, List, Modal, Row } from "antd";
import { useState } from "react";
import styled from "styled-components";

interface Props {
  open: boolean;
  onCancel: () => void;
  answer: string
}

export const AnswerModal: React.FC<Props> = ({ open, onCancel, answer }) => {
  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      className="modal-container"
      width={600}
    >
      <HeaderWrapper>
        <div className="confirm-description" style={{justifyContent: "center"}}>
          {answer}
        </div>
        <div className="journey-btn-group">
          <button className="journey-btn-close" onClick={onCancel}>
            Close
          </button>
        </div>
      </HeaderWrapper>
    </Modal>
  );
};

const HeaderWrapper = styled.div`
  .journey-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: static;
  }

  .journey-btn-close {
    width: 220px;
    background: #fad121;
    border-radius: 73px;
    background: #fad121;
    border-radius: 73px;
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #231f20;
    padding: 10px 20px;
    padding-top: 15px;
    cursor: pointer;

    background-color: #0094e3;
  }

  .confirm-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 12px;
    color: #231F20;

  }

  .confirm-description {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #231F20;
    margin-bottom: 28px;
  }
  .confirm-warning {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 18px;
    color: #6f6f6f;
  }
`;
