//Logo
import Logo from "../assets/images/logo.svg";
import ShadowLogo from "../assets/images/logo-eyes.svg";
import TextLogo from "../assets/images/logo-full.png";

//BG
import Bg from "../assets/images/bg.png";
import FooterBG from "../assets/images/footer-bg.png";
import JourneyBG from "../assets/images/journey-bg.png";
import MarkerBG from "../assets/images/marker.png";

//ICONS
import NFTPlaceholder from "../assets/icons/nft-placeholder.svg";
import FracturePlaceholder from "../assets/icons/fracture-placeholder.png";
import CoolscoreIcon from "../assets/icons/coolcats-score.svg";
import JourneyScoreIcon from "../assets/icons/journey-score.svg";
import UserIcon from "../assets/icons/user-icon.svg";
import QuestIcon from "../assets/icons/quest-icon.svg";
import QuestActiveIcon from "../assets/icons/quest-active-icon.svg";
import QuestLockIcon from "../assets/icons/quest-lock-icon.svg";

//Images
import Journey2Comic from "../assets/images/journey2-comic.jpg";
import Journey3Puzzle from "../assets/images/Journey3Puzzle.png";

//Journey4
// import Journey4Comic1 from "../assets/images/journey4/1.png";
// import Journey4Comic2 from "../assets/images/journey4/2.png";
// import Journey4Comic3 from "../assets/images/journey4/3.png";
// import Journey4Comic4 from "../assets/images/journey4/4.png";
// import Journey4Comic5 from "../assets/images/journey4/5.png";
// import Journey4Comic6 from "../assets/images/journey4/6.png";
// import Journey4Comic7 from "../assets/images/journey4/7.png";
// import Journey4Comic8 from "../assets/images/journey4/8.png";
// import Journey4Comic9 from "../assets/images/journey4/9.png";
// import Journey4Puzzle from "../assets/images/journey4/puzzle.png";

//Journey5
// import Journey5Comic1 from "../assets/images/journey5/1.jpg";
// import Journey5Comic2 from "../assets/images/journey5/2.jpg";
// import Journey5Comic3 from "../assets/images/journey5/3.jpg";
// import Journey5Comic4 from "../assets/images/journey5/4.jpg";
// import Journey5Comic5 from "../assets/images/journey5/5.jpg";
// import Journey5Comic6 from "../assets/images/journey5/6.jpg";
// import Journey5Comic7 from "../assets/images/journey5/7.jpg";
// import Journey5Comic8 from "../assets/images/journey5/8.jpg";



//Journey6
import Journey6Comic1 from "../assets/images/journey6/1.jpg";
import Journey6Comic2 from "../assets/images/journey6/2.jpg";
import Journey6Comic3 from "../assets/images/journey6/3.jpg";
import Journey6Comic4 from "../assets/images/journey6/4.jpg";
import Journey6Comic5 from "../assets/images/journey6/5.jpg";
import Journey6Comic6 from "../assets/images/journey6/6.jpg";
import Journey6Puzzle from "../assets/images/journey6/puzzle.png";

//Achievement
import AchievementSilver from "../assets/images/achievement-silver.svg";
import AchievementGold from "../assets/images/achievement-gold.svg";

//Desk Puzzle
import DeskPuzzleBg from "../assets/images/desk-puzzle/desk.png";
import Item1 from "../assets/images/desk-puzzle/item1.png";
import Item1Hover from "../assets/images/desk-puzzle/item1-hover.png";

//Mint Reward
import MintReward0 from "../assets/images/mint-rewards/0.jpg";
import MintReward1 from "../assets/images/mint-rewards/1.jpg";
import MintReward2 from "../assets/images/mint-rewards/2.jpg";
import MintReward3 from "../assets/images/mint-rewards/3.jpg";

//Shadow
import MintReward4 from "../assets/images/mint-rewards/4.jpg";
import MintReward5 from "../assets/images/mint-rewards/5.jpg";
import MintReward6 from "../assets/images/mint-rewards/6.jpg";
import MintReward7 from "../assets/images/mint-rewards/7.jpg";

//Journey 7
import Journey7Comic from "../assets/images/journey7/comic.jpg";
import Journey7ComicDifferent from "../assets/images/journey7/Journey7_SpotTheDifference.png";

//Journey8
import Journey8Comic1 from "../assets/images/journey-8/12.jpg"
import Journey8Comic2 from "../assets/images/journey-8/22.jpg"
import Journey8Comic3 from "../assets/images/journey-8/32.jpg"
import Journey8Comic4 from "../assets/images/journey-8/42.jpg"
import Journey8Comic5 from "../assets/images/journey-8/52.jpg"

//Journey9
import Journey9Comic from "../assets/images/journey9/comic.jpg"
import Journey9Puzzle from "../assets/images/journey9/puzzle.png"

//Journey10
import Journey10Comic1 from "../assets/images/journey10/comic1.jpg"
import Journey10Comic2 from "../assets/images/journey10/comic2.jpg"
import Journey10Comic3 from "../assets/images/journey10/comic3.jpg"
import Journey10Comic4 from "../assets/images/journey10/comic4.jpg"
import Journey10Puzzle from "../assets/images/journey10/puzzle.png"

//Journey11
import Journey11Comic1 from "../assets/images/journey11/13.jpg"
import Journey11Comic2 from "../assets/images/journey11/23.jpg"
import Journey11Comic3 from "../assets/images/journey11/33.jpg"
import Journey11Comic4 from "../assets/images/journey11/43.jpg"
import Journey11Comic5 from "../assets/images/journey11/53.jpg"
import Journey11Puzzle from "../assets/images/journey11/puzzle.png"

//Journey12
import Journey12Comic from "../assets/images/journey12/comic.jpg"
import Journey12Puzzle from "../assets/images/journey12/puzzle.png"

//Journey13
import Journey13Comic from "../assets/images/journey13/comic.jpg"

//Journey14
import Journey14Comic1 from "../assets/images/journey14/1.jpg"
import Journey14Comic2 from "../assets/images/journey14/2.png"
import Journey14Comic3 from "../assets/images/journey14/3.jpg"
import Journey14Comic4 from "../assets/images/journey14/4.jpg"
import Journey14Puzzle from "../assets/images/journey14/puzzle.png"

//Journey15
import Journey15Comic from "../assets/images/journey15/comic.jpg"
import Journey15Puzzle from "../assets/images/journey15/puzzle.png"

//Journey16
import Journey16Comic from "../assets/images/journey16/comic.jpg"

//Drag Puzzle
import Puzzle1 from "../assets/images/journey16/puzzle/1.jpg";
import Puzzle2 from "../assets/images/journey16/puzzle/2.jpg";
import Puzzle3 from "../assets/images/journey16/puzzle/3.jpg";
import Puzzle4 from "../assets/images/journey16/puzzle/4.jpg";
import Puzzle5 from "../assets/images/journey16/puzzle/5.jpg";
import Puzzle6 from "../assets/images/journey16/puzzle/6.jpg";
import Puzzle7 from "../assets/images/journey16/puzzle/7.jpg";
import Puzzle8 from "../assets/images/journey16/puzzle/8.jpg";
import Puzzle9 from "../assets/images/journey16/puzzle/9.jpg";

//Journey17
import Journey17Comic from "../assets/images/journey17/comic.jpg"
import Journey17Puzzle from "../assets/images/journey17/puzzle.png"

//Journey18
import Journey18Comic from "../assets/images/journey18/comic.jpg"
import Journey18Puzzle from "../assets/images/journey18/puzzle.png"

const HEADER = {
  LOGO: Logo,
  SHADOWLOGO: ShadowLogo,
};

const HOMEPAGE = {
  BG: Bg,
  JOURNEYBG: JourneyBG,
  NFTPLACEHOLDER: NFTPlaceholder,
  FRACTUREPLACEHOLDER: FracturePlaceholder,
  MARKER: MarkerBG,
  COOLSCOREICON: CoolscoreIcon,
  JOURNEYSCOREICON: JourneyScoreIcon,
  USERICON: UserIcon,
  QUESTICON: QuestIcon,
  QUESTACTIVEICON: QuestActiveIcon,
  QUESTLOCKICON: QuestLockIcon,
  JOURNEY2COMIC: Journey2Comic,
  JOURNEY3PUZZLE: Journey3Puzzle,
};

const FOOTER = {
  FOOTERBG: FooterBG,
  LOGO: TextLogo,
};

// const JOURNEY4 = {
//   COMIC1: Journey4Comic1,
//   COMIC2: Journey4Comic2,
//   COMIC3: Journey4Comic3,
//   COMIC4: Journey4Comic4,
//   COMIC5: Journey4Comic5,
//   COMIC6: Journey4Comic6,
//   COMIC7: Journey4Comic7,
//   COMIC8: Journey4Comic8,
//   COMIC9: Journey4Comic9,
//   PUZZLE: Journey4Puzzle,
// };

// const JOURNEY5 = {
//   COMIC1: Journey5Comic1,
//   COMIC2: Journey5Comic2,
//   COMIC3: Journey5Comic3,
//   COMIC4: Journey5Comic4,
//   COMIC5: Journey5Comic5,
//   COMIC6: Journey5Comic6,
//   COMIC7: Journey5Comic7,
//   COMIC8: Journey5Comic8,
// };

const JOURNEY6 = {
  COMIC1: Journey6Comic1,
  COMIC2: Journey6Comic2,
  COMIC3: Journey6Comic3,
  COMIC4: Journey6Comic4,
  COMIC5: Journey6Comic5,
  COMIC6: Journey6Comic6,
  PUZZLE: Journey6Puzzle,
};

const PUZZLE = {
  PUZZLE1: Puzzle1,
  PUZZLE2: Puzzle2,
  PUZZLE3: Puzzle3,
  PUZZLE4: Puzzle4,
  PUZZLE5: Puzzle5,
  PUZZLE6: Puzzle6,
  PUZZLE7: Puzzle7,
  PUZZLE8: Puzzle8,
  PUZZLE9: Puzzle9,
};

const ACHIEVEMENT = {
  ACHIEVEMENTSILVER: AchievementSilver,
  ACHIEVEMENTGOLD: AchievementGold,
};

const DESKPUZZLE = {
  PUZZLEBG: DeskPuzzleBg,
  ITEMONE: Item1,
  ITEMONEHOVER: Item1Hover,
};

const MINTREWARD = {
  REWARD0: MintReward0,
  REWARD1: MintReward1,
  REWARD2: MintReward2,
  REWARD3: MintReward3,
  REWARD4: MintReward4,
  REWARD5: MintReward5,
  REWARD6: MintReward6,
  REWARD7: MintReward7,
};

const JOURNEY7 = {
  JOURNEYCOMIC: Journey7Comic,
  JOURNEYCOMICDIFFERENT: Journey7ComicDifferent,
};
const JOURNEY8 = {
  JOURNEYCOMIC1: Journey8Comic1,
  JOURNEYCOMIC2: Journey8Comic2,
  JOURNEYCOMIC3: Journey8Comic3,
  JOURNEYCOMIC4: Journey8Comic4,
  JOURNEYCOMIC5: Journey8Comic5,
};

const JOURNEY9 = {
  JOURNEYCOMIC: Journey9Comic,
  JOURNEYPUZZLE : Journey9Puzzle
}

const JOURNEY10 = {
  JOURNEYCOMIC1: Journey10Comic1,
  JOURNEYCOMIC2: Journey10Comic2,
  JOURNEYCOMIC3: Journey10Comic3,
  JOURNEYCOMIC4: Journey10Comic4,
  JOURNEYPUZZLE : Journey10Puzzle
}

const JOURNEY11 = {
  JOURNEYCOMIC1: Journey11Comic1,
  JOURNEYCOMIC2: Journey11Comic2,
  JOURNEYCOMIC3: Journey11Comic3,
  JOURNEYCOMIC4: Journey11Comic4,
  JOURNEYCOMIC5: Journey11Comic5,
  JOURNEYPUZZLE : Journey11Puzzle
}

const JOURNEY12 = {
  JOURNEYCOMIC: Journey12Comic,
  JOURNEYPUZZLE : Journey12Puzzle
}

const JOURNEY13 = {
  JOURNEYCOMIC: Journey13Comic,
}

const JOURNEY14 = {
  JOURNEYCOMIC1: Journey14Comic1,
  JOURNEYCOMIC2: Journey14Comic2,
  JOURNEYCOMIC3: Journey14Comic3,
  JOURNEYCOMIC4: Journey14Comic4,
  JOURNEYPUZZLE : Journey14Puzzle
}

const JOURNEY15 = {
  JOURNEYCOMIC: Journey15Comic,
  JOURNEYPUZZLE : Journey15Puzzle
}

const JOURNEY16 = {
  JOURNEYCOMIC: Journey16Comic,
}

const JOURNEY17 = {
  JOURNEYCOMIC: Journey17Comic,
  JOURNEYPUZZLE : Journey17Puzzle
}
const JOURNEY18 = {
  JOURNEYCOMIC: Journey18Comic,
  JOURNEYPUZZLE : Journey18Puzzle
}

export {
  HEADER,
  HOMEPAGE,
  FOOTER,
  // JOURNEY4,
  PUZZLE,
  // JOURNEY5,
  JOURNEY6,
  ACHIEVEMENT,
  DESKPUZZLE,
  MINTREWARD,
  JOURNEY7,
  JOURNEY8,
  JOURNEY9,
  JOURNEY10,
  JOURNEY11,
  JOURNEY12,
  JOURNEY13,
  JOURNEY14,
  JOURNEY15,
  JOURNEY16,
  JOURNEY17,
  JOURNEY18
};
