import { useState } from "react";
import { HEADER } from "src/constants/images";

interface Props {
  closeOverlay: () => void;
}

export const Overlay: React.FC<Props> = ({ closeOverlay }) => {
  // if (!isWebBrowser) {
  //   // @ts-ignore
  //   document.querySelector("#root").style.pointerEvents = "none";
  // }

  return (
    <div
      style={{
        // position: "fixed",
        // top: 0,
        // left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 999,
        // pointerEvents: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      <div
        style={{
          background: "#231F20",
          // borderRadius: "20px",
          padding: "42px",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      >
        <div className="header-logo" style={{ paddingBottom: "60px" }}>
          <img src={HEADER.LOGO} />
        </div>
        <p className="mobile-warning-title"> MOBILE WARNING</p>
        <br></br>
        <p className="mobile-warning-text">
          Unfortunately the site is not optimized for mobile. <br></br>Some
          features may not work as intended.
        </p>
        <br></br>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="journey-btn"
            style={{ cursor: "pointer" }}
            onClick={() => closeOverlay()}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  );
};
