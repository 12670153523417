import React, { useState, useEffect, useMemo, useCallback } from "react";
import { getCount, getCountCooltopia } from "src/helpers/stakeSelectors";
import { TokenData } from "src/services/api";

interface IProps {
  children?: React.ReactNode;
}

interface ScoreProps {
    coolScore: number,
    setCoolScore: (cb: any) => void,
    journeyScore: number,
    selectedCats: number[],
    setSelectedCats: (cb: any) => void,
    selectedPets: number[],
    setSelectedPets: (cb: any) => void,
    selectedCooltopia: number[],
    setSelectedCooltopia: (cb: any) => void,
    selectedWolves: number[],
    setSelectedWolves: (cb: any) => void,
    catsData: TokenData[],
    setCatsData: (cb: any) => void,
    petsData: TokenData[],
    setPetsData: (cb: any) => void,
    cooltopiaData: TokenData[],
    setCooltopiaData: (cb: any) => void,
    wolvesData: TokenData[],
    setWolvesData: (cb: any) => void,
    answer: string,
    setAnswer: (cb: any) => void,
}

export const ScoreContext = React.createContext<ScoreProps>({} as ScoreProps);

export const ScoreProvider: React.FC<IProps> = ({ children }) => {
  const [coolScore, setCoolScore] = useState(0);
  const [selectedCats, setSelectedCats] = useState<number[]>([]);
  const [selectedPets, setSelectedPets] = useState<number[]>([]);
  const [selectedCooltopia, setSelectedCooltopia] = useState<number[]>([]);
  const [selectedWolves, setSelectedWolves] = useState<number[]>([]);

  const [catsData, setCatsData] = useState<TokenData[]>([]);
  const [petsData, setPetsData] = useState<TokenData[]>([]);
  const [cooltopiaData, setCooltopiaData] = useState<TokenData[]>([]);
  const [wolvesData, setWolvesData] = useState<TokenData[]>([]);

  const [answer, setAnswer] = useState("");

  const journeyScore = useMemo(() => {
    const catScore = Math.min(getCount(catsData, selectedCats), 5) * 100;
    const petScore = Math.min(getCount(petsData, selectedPets), 6) * 50;
    const tier1Score = getCountCooltopia(cooltopiaData, selectedCooltopia.filter((id) => Math.floor(id / 1000) === 77), cooltopiaData.filter((item) => Math.floor(item.id / 1000) === 77)) * 200;
    const tier2Score = getCountCooltopia(cooltopiaData, selectedCooltopia.filter((id) => Math.floor(id/1000) === 78), cooltopiaData.filter((item) => Math.floor(item.id / 1000) === 78)) * 300;
    const tier3Score = getCountCooltopia(cooltopiaData, selectedCooltopia.filter((id) => Math.floor(id / 1000) === 79), cooltopiaData.filter((item) => Math.floor(item.id / 1000) === 79))* 500;
    const answerScore = Math.min(answer?.length, 1) * 300;
    const wolvesCount = getCount(wolvesData, selectedWolves)
    console.log(wolvesCount)
    let wolvesScore = 0;
    for (let i = 1; i <= wolvesCount; i++) {
      if (i === 1) {
        wolvesScore = 75
      } else {
        wolvesScore += 75 * 0.9 ** (i - 1)
      }
      console.log(wolvesScore)
    }
    wolvesScore = Math.ceil(wolvesScore)
    const journeyscore = !isNaN(catScore + petScore + answerScore + tier1Score + tier2Score + tier3Score + wolvesScore)
      ? catScore + petScore + answerScore + tier1Score + tier2Score + tier3Score + wolvesScore
      : 0;
    return journeyscore;
  }, [catsData, petsData, cooltopiaData, wolvesData, selectedCats, selectedPets, selectedCooltopia, selectedWolves, answer]);

  return (
    <ScoreContext.Provider
      value={{
        coolScore,
        setCoolScore,
        journeyScore,
        selectedCats,
        setSelectedCats,
        selectedPets,
        setSelectedPets,
        selectedCooltopia,
        setSelectedCooltopia,
        catsData,
        setCatsData,
        petsData,
        setPetsData,
        cooltopiaData,
        setCooltopiaData,
        wolvesData,
        setWolvesData,
        selectedWolves,
        setSelectedWolves,
        answer, 
        setAnswer,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};
