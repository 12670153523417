import {
  fetchAchievements,
  sendAchievements,
  transferAchievements,
  fetchTransferRequests,
  getConnectedWalletInfo,
  connectWalletToUserAccount,
  getConnectionRequest,
  acceptConnectionRequest,
} from '../../../services/api';
import { useAccount, useSignMessage } from 'wagmi';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextField, Button, Box } from '@mui/material';
import { notification } from 'antd';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const TAB_ITEM = {
  COOLCATS: 1,
  ACHIEVEMENTS: 2,
};

export default function AchievementTransfer() {
  const { address } = useAccount();
  // const address = '0xa62f3fcd3a40bff115029252e0a9b1b9d391fbd8';
  console.log(address, ':address');

  //States
  const [refresh, setRefresh] = useState(false);
  const [disableTransfer, setDisableTransfer] = useState(false);
  const [acheivements, setAchievements] = useState([]);
  const [walletInfo, setWalletInfo] = useState([]);
  const [selectedAcheivements, setSelectedAchievements] = useState<any>([]);
  const [transferRequests, setTransferRequests] = useState<any>([]);
  const [receiveAchievementPayload, setReceiveAchievementPayload] =
    useState<any>(null);
  const [receiver, setReceiver] = useState('');
  const [receiverSignature, setReceiverSignature] = useState('');
  const [selectedTab, setSelectedTab] = useState(TAB_ITEM.COOLCATS);

  const [connectionRequest, setConnectionRequest] = useState([]);

  console.log(acheivements, ':aachi');

  // coolcats tabs
  const [walletAddress2, setWalletAddress2] = useState('');

  //Checkbox
  const [checkbox, setCheckbox] = useState<any>({});

  const handleChange = (event: any) => {
    setCheckbox({
      ...checkbox,
      [event.target.name]: event.target.checked,
    });
    if (event.target.checked) {
      setSelectedAchievements([...selectedAcheivements, event.target.name]);
    } else {
      setSelectedAchievements(
        selectedAcheivements.filter((el: any) => el !== event.target.name)
      );
    }
  };

  const fetchTransferTabData = async () => {
    const achievementData = await fetchAchievements(address);
    const transferRequestData = await fetchTransferRequests(address);
    setAchievements(achievementData);
    setTransferRequests(transferRequestData);
    setSelectedAchievements([]);
    setRefresh(false);
  };
  const fetchCoolcatsData = async () => {
    const walletInfoData = await getConnectedWalletInfo(address);
    // const transferRequestData = await fetchTransferRequests(address);
    setWalletInfo(walletInfoData);
    // setTransferRequests(transferRequestData);
    // setSelectedAchievements([]);
    setRefresh(false);
  };

  const fetchConnectionRequest = async () => {
    const connectionReq = await getConnectionRequest(address);
    setConnectionRequest(connectionReq);
    setRefresh(false);
  };

  console.log(walletInfo, ':info');
  console.log(connectionRequest, ':info');

  useEffect(() => {
    if (address || refresh) {
      fetchTransferTabData();
    }
  }, [address, refresh]);

  useEffect(() => {
    if (address || refresh) {
      fetchCoolcatsData();
    } else {
      setWalletInfo([]);
    }
  }, [address, refresh]);

  useEffect(() => {
    if (address) {
      fetchConnectionRequest();
    } else {
      setConnectionRequest([]);
    }
  }, [address, refresh]);

  useEffect(() => {
    setCheckbox(
      acheivements.reduce(
        (prev: any, curr: any) => ({ ...prev, [curr.key]: false }),
        {}
      )
    );
  }, [acheivements, refresh]);

  const { signMessage: senderSign } = useSignMessage({
    async onSuccess(data, variables) {
      console.log('Success', data, variables.message);

      const reqBody = {
        tokens: selectedAcheivements,
        sender: address,
        senderSignature: data,
        receiver: receiver,
      };

      try {
        const resp = await sendAchievements(reqBody);

        if (resp.ERROR) {
          notification.error({ message: resp.ERROR });
          return;
        }

        notification.success({ message: 'Transfer request sent succesfully!' });
      } catch (error) {
        notification.error({ message: 'Error while initiating transfer!' });
        console.log({ error });
      } finally {
        setRefresh(true);
        setReceiver('');
        setSelectedAchievements([]);
        setDisableTransfer(false);
      }
    },
    onError(error) {
      notification.error({ message: error.message });
    },
  });
  const { signMessage: senderSignForConnect } = useSignMessage({
    async onSuccess(data, variables) {
      console.log('Success', data, variables.message);

      const reqBody = {
        wallet1: address,
        wallet2: receiver,
        signature: data,
      };

      try {
        const resp = await connectWalletToUserAccount(reqBody);

        if (resp.ERROR) {
          notification.error({ message: resp.ERROR });
          return;
        }

        notification.success({
          message: 'Connect request to wallet2 sent successfully!',
        });
      } catch (error) {
        notification.error({ message: 'Error while initiating connection!' });
        console.log({ error });
      } finally {
        setRefresh(true);
        setReceiver('');
        // setSelectedAchievements([]);
        setDisableTransfer(false);
      }
    },
    onError(error) {
      notification.error({ message: error.message });
    },
  });
  const { signMessage: acceptWalletConnect } = useSignMessage({
    async onSuccess(data, variables) {
      console.log('Success', data, variables.message);

      const reqBody = {
        wallet1: connectionRequest[0],
        wallet2: address,
        signature: data,
      };

      try {
        const resp = await acceptConnectionRequest(reqBody);

        if (resp.ERROR) {
          notification.error({ message: resp.ERROR });
          return;
        }

        notification.success({ message: 'Connection accepted succesfully!' });
      } catch (error) {
        notification.error({ message: 'Error while accepting connection!' });
        console.log({ error });
      } finally {
        setRefresh(true);
        setReceiver('');
        setConnectionRequest([]);
        // setSelectedAchievements([]);
        setDisableTransfer(false);
      }
    },
    onError(error) {
      notification.error({ message: error.message });
    },
  });

  useEffect(() => {
    if (receiveAchievementPayload) {
      (async () =>
        receiverSign({
          message: `Receive Achievements from ${receiveAchievementPayload?.sender}`,
        }))();
    }
  }, [receiveAchievementPayload]);

  const { signMessage: receiverSign } = useSignMessage({
    async onSuccess(data, variables) {
      console.log('Success', data, variables.message);
      setReceiverSignature(data);
    },
    onError(error) {
      notification.error({ message: error.message });
    },
  });

  useEffect(() => {
    if (receiverSignature && receiveAchievementPayload) {
      (async () => {
        console.log(receiveAchievementPayload);
        const reqBody = {
          ...receiveAchievementPayload,
          receiverSignature: receiverSignature,
        };
        console.log(reqBody);

        try {
          const resp = await transferAchievements(reqBody);

          if (resp.ERROR) {
            notification.error({ message: resp.ERROR });
            return;
          }

          notification.success({ message: 'Transfer approved succesfully!' });
        } catch (error) {
          notification.error({ message: 'Error while completing transfer!' });
          console.log({ error });
        } finally {
          setReceiverSignature('');
          setReceiveAchievementPayload(null);
          setRefresh(true);
        }
      })();
    }
  }, [receiverSignature, receiveAchievementPayload]);

  const handleSend = async () => {
    if (selectedAcheivements.length === 0) {
      notification.error({
        message: 'Please select at least one acheivement!',
      });
      return;
    }
    if (address === receiver) {
      notification.error({
        message: 'You cannot transfer acheivement to yourself!',
      });
      return;
    }
    setDisableTransfer(true);
    senderSign({
      message: `Transfer Achievements to ${receiver}`,
    });
  };
  const connectWalletToUser = async () => {
    // if (selectedAcheivements.length === 0) {
    //   notification.error({
    //     message: 'Please select at least one acheivement!',
    //   });
    //   return;
    // }
    if (!receiver) {
      notification.error({
        message: 'PLease enter wallet 2 address!',
      });
      return;
    }
    if (address === receiver) {
      notification.error({
        message: 'You cannot connect to yourself!',
      });
      return;
    }
    setDisableTransfer(true);
    senderSignForConnect({
      message: `Connect Wallet to ${receiver}`,
    });
  };

  const acceptConnection = async () => {
    setDisableTransfer(true);
    acceptWalletConnect({
      message: `Accept wallet connection by ${connectionRequest[0]}`,
    });
  };

  return (
    <TransferContainer>
      <div className='transfer-tabs'>
        <div
          onClick={() => setSelectedTab(TAB_ITEM.COOLCATS)}
          className={[
            'tab-item',
            selectedTab === TAB_ITEM.COOLCATS ? 'active' : '',
          ].join(' ')}
        >
          Coolcats
        </div>
        <div
          onClick={() => setSelectedTab(TAB_ITEM.ACHIEVEMENTS)}
          className={[
            'tab-item',
            selectedTab === TAB_ITEM.ACHIEVEMENTS ? 'active' : '',
          ].join(' ')}
        >
          Achievements
        </div>
      </div>
      {selectedTab === TAB_ITEM.ACHIEVEMENTS && (
        <AchievementContainer>
          <div className='transaction'>
            <h1>Send Achievements</h1>
            <div className='achievements'>
              {acheivements &&
                acheivements.map((achievement: any, index: number) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkbox[achievement.key] || false}
                          onChange={handleChange}
                          name={achievement.key}
                          disabled={achievement.disabled}
                        />
                      }
                      label={achievement.key}
                      key={index}
                    />
                  );
                })}
            </div>
            <Box display='flex' gap={2} p={4}>
              <TextField
                type='text'
                label='Receiver Address'
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
              />
              <Button
                variant='contained'
                onClick={handleSend}
                disabled={disableTransfer}
              >
                Transfer
              </Button>
            </Box>
          </div>
          <div className='transaction'>
            <h1>Receive Achievements</h1>
            <div className='achievements'>
              {transferRequests &&
                transferRequests.map((request: any) => {
                  return (
                    <Box
                      mb={2}
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                    >
                      <div>
                        <strong>Sender : </strong>
                        {request?.sender}
                      </div>
                      <strong>Tokens</strong>
                      {request?.tokens &&
                        request?.tokens.map((token: any) => {
                          return <div>{token}</div>;
                        })}
                      <Box display='flex' gap={2} p={4}>
                        <Button
                          variant='contained'
                          onClick={() => {
                            setReceiveAchievementPayload(request);
                          }}
                        >
                          Receive
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
            </div>
          </div>
        </AchievementContainer>
      )}

      {selectedTab === TAB_ITEM.COOLCATS && (
        // <NftContainer>

        <AchievementContainer>
          <div className='transaction'>
            <h1>Connect Wallet</h1>
            <div className='achievements'>
              {walletInfo &&
                walletInfo.map((connected: any, index: number) => {
                  return (
                    <div>
                      <strong>Connected Wallet:</strong>
                      {connected?.wallet1?.toLowerCase() ===
                      address?.toLowerCase()
                        ? connected?.wallet2
                        : connected?.wallet1}
                    </div>
                  );
                })}
            </div>
            <Box display='flex' gap={2} p={4}>
              <TextField
                type='text'
                label='Receiver Address'
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
              />
              <Button
                variant='contained'
                onClick={connectWalletToUser}
                // disabled={walletInfo?.length !== 0}
              >
                Connect Wallet 2
              </Button>
            </Box>
          </div>
          <div className='transaction'>
            <h1>Accept Connection</h1>
            <div className='achievements'>
              {connectionRequest &&
                connectionRequest.map((request: any) => {
                  return (
                    <Box
                      mb={2}
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                    >
                      <div>
                        <strong>Sender : </strong>
                        {request}
                        <p>Wants to connect</p>
                      </div>

                      <Box display='flex' gap={2} p={4}>
                        <Button variant='contained' onClick={acceptConnection}>
                          Accept Connection
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
              {connectionRequest?.length === 0 && <p>No Connection Request</p>}
            </div>
          </div>
        </AchievementContainer>
        // </NftContainer>
      )}
    </TransferContainer>
  );
}

const TransferContainer = styled.div`
  margin: 50px 0;

  .transfer-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .tab-item {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    padding: 4px 8px;
    &.active {
      border-bottom: 4px solid #1976d2;
    }
  }
`;

const NftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;

const AchievementContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap; 40px;
  padding: 50px;

  .transaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
