import { Popconfirm, Popover } from "antd";
import styled from "styled-components";
import { THEME, deviceWidth } from "../../../config";
import { Button } from "../../atoms/Button";
import { MetaMaskContext } from "../../../hoc/Metamask";
import { useContext, useEffect } from "react";
import { limitCharacter } from "../../../helpers/limitCharacter";
// import { useDeviceType } from "../../../hooks";
import { Close, Menu, Wallet } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useLayout } from "../../../hoc/LayoutProvider";
import { socialContentData } from "../../../constants";
import { IconButton } from "@mui/material";
import { HEADER } from "../../../constants/images";
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet.svg";

import {
  useWeb3ModalTheme,
  Web3Button,
  Web3NetworkSwitch,
} from "@web3modal/react";
import { useWeb3Modal } from "@web3modal/react";
import { useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { CoolScore } from "../Score";
import DelegateCashButton from "delegatecash-button-react";
import { fetchCoolScore } from "src/services/api";
import { useScreenDetector } from "src/hooks";
import { useLocation } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export const NavBar = () => {
  // const { connect, account, isActive, disconnect } =
  //   useContext(MetaMaskContext);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { isWebBrowser } = useScreenDetector();

  async function onOpen() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  // const { windowSize } = useDeviceType();
  const { isMobileMenuVisible, setIsMobileMenuVisible } = useLayout();
  const navigate = useNavigate();

  const handleLinkClick = (link: string) => {
    if (!link) return;
    navigate(link);
    setIsMobileMenuVisible && setIsMobileMenuVisible(false);
  };

  // const [coolScore, setCoolScore] = useState(0);
  // useEffect(() => {
  //   console.log({ address });
  //   if (address) {
  //     console.log("Fetching ");
  //     fetchCoolScore(address).then((response) => {
  //       console.log("Coolscore: ", response?.coolScore);
  //       setCoolScore(Math.round(Number(response?.coolScore)));
  //     });
  //   } else {
  //     setCoolScore(0);
  //   }
  // }, [address]);

  return (
    <>
      <HeaderWrapper>
        <div className="container">
          <SocialContentWrapper>
            {/* {socialContentData.map(({ Icon, title, link }, idx) => (
              <a
                className="social"
                key={`social_${idx}`}
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                <Icon />
              </a>
            ))} */}
            <div>
              {/* <span className="pic arrow-left"></span> */}
              {isWebBrowser && location.pathname !== "/reward" ? (
                <Button
                  btntext={"Home"}
                  className="btn-wallet"
                  btntype="neutral"
                  btnSize="very-small"
                  // eslint-disable-next-line no-restricted-globals
                  onClick={() =>
                    (window.location.href = "https://coolcatsnft.com")
                  }
                ></Button>
              ) : isWebBrowser && (
                <Link to="/">
                  <Button
                    btntext={"Back"}
                    className="btn-wallet"
                    btntype="neutral"
                    btnSize="very-small"
                  ></Button>
                </Link>
              )}
            </div>
          </SocialContentWrapper>

          <div className="header-logo" onClick={() => handleLinkClick("/")}>
            <img src={HEADER.LOGO} />
          </div>

          <div className="header-btn">
            {/* <div style={{ marginRight: "12px" }}>
              <CoolScore score={coolScore} address={address} />
            </div> */}

            {/* {isConnected && (
              <DelegateCashButton
                label="Delegate cash"
                connectedWallet={address}
                rpcUrl="https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
                rounded={true}
                // onButtonClick={event:any => console.log(event.detail)}
                onWalletSelect={(event: { detail: any }) =>
                  console.log(event.detail)
                }
              />
            )} */}
            {isConnected && isWebBrowser && location.pathname !== "/reward" &&
              <Link to="/reward">
                <Button
                  btntext={loading ? "Loading..." : "Journey Rewards"}
                  className="btn-wallet btn-reward"
                  btntype="neutral"
                  btnSize="very-small"
                />
              </Link>
            }

            {isWebBrowser ? 
              <div className="connect-btn">
              <ConnectButton label="Connect" accountStatus="address" showBalance={false}  /> </div> : null}
          </div>
        </div>
      </HeaderWrapper>

      {/* <NavbarWrapper>
        <div className="container">
          <div className="nav-link active">Home</div>
          <div className="nav-link">Nfts</div>
          <div className="nav-link">News</div>
          <div className="nav-link">About</div>
          <div className="nav-link">Tools</div>
          <div className="nav-link">Shop</div>
        </div>
      </NavbarWrapper> */}
    </>
  );
};

const HeaderWrapper = styled.div`
  background-color: #231f20;
  z-index: 1;
  position: relative;

  .container {
    width: 85%;
    height: 90px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .header-logo {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .header-logo img {
    width: 80.88px;
    cursor: pointer;
  }

  .header-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .cool-score {
    background: #fad121;
    border-radius: 10px;

    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: 2px 12px;
    margin-right: 12px;

    span {
      margin-top: 4px;
      font-weight: bolder;
      padding: 0px 2px;
    }

    .small {
      color: white;
      font-weight: 500;
      font-size: 10px;
    }
  }

  .btn-wallet {
    color: #fad121;
    border: 1px solid #fad121;
    background-color: transparent;
    font-weight: bold;
    width: 160px;
    height: 44px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-top: 10px;
  }

  .btn-wallet:hover {
    color: #fad121;
    border: 1px solid #fad121;
  }

  .btn-reward {
    background: #fad121;
    color: #000000;
    margin-right: 10px;
    box-shadow: 0 0 10px #fad121;
    width: auto;
  }

  .btn-reward:hover {
    color: #000000;
    box-shadow: 0 0 15px #fad121;
    transition: box-shadow 0.3s;
  }

  .icon-btn {
    width: 45px;
    border-radius: 50%;
    background-color: #fad121;
  }
`;

const NavbarWrapper = styled.div`
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));

  .container {
    width: 50%;
    height: 44px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0px 25px;
    padding-top: 5px;
    height: 100%;
  }

  .active {
    background-color: #0094e3;
    color: #ffffff;
  }
`;

const SocialContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;

  .btn-wallet {
    position: relative;
  }

  .btn-wallet::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid #fad121;
    position: absolute;
    left: 15px;
    top: 36%;
  }

  & .social {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    border-radius: 8px;
    cursor: pointer;

    & > span {
      ${THEME.typography.body3}
      margin-left: 16px;
    }
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
  & :hover {
    color: #fad121;
  }
`;
