const merkelTree = {
  "0xF8500414e289f90e9be286dbC2d1dD1890ece806": {
    "tokenId": 0,
    "proof": [
      "0x7287454e3be7dd5a10636060a2a7ded78a6aa17eeb89b7d42d0957b42c60eb20",
      "0x82939f03fda3c07d1bcc135ab827af54c4b1c5637feb27be4abe534d292b0b00",
      "0xdc5a67131eb2de9df2cb8377f3a4a0da7c8f669482e2b2f26d65ce5d9fbf5c52"
    ]
  },
  "0x1b068DD66ada72e6C4f75368978EdA09906B6708": {
    "tokenId": 3,
    "proof": [
      "0x6b15fe89805d80362236d02d9c0927413a0e749bc04e78bce26054b5e6675bdc",
      "0x82939f03fda3c07d1bcc135ab827af54c4b1c5637feb27be4abe534d292b0b00",
      "0xdc5a67131eb2de9df2cb8377f3a4a0da7c8f669482e2b2f26d65ce5d9fbf5c52"
    ]
  },
  "0x1852E500ABD50d623878E63d5fAd8aaC416d56Bf": {
    "tokenId": 2,
    "proof": [
      "0xd0f1c0961557ef7939656f54dec8135d8ba5ca449a5adfdfc1c74df702542f61",
      "0x3243b6d884f50c168e5abc4e777f5802fe5edc82ba7d60ac485c2c15a1f4ceef"
    ]
  },
  "0x1DA2FA875402a03F75E61De54102aF88f0ebFDD1": {
    "tokenId": 0,
    "proof": [
      "0xd6736bd3d52d12225cc9cb82162ba1925f81f8ab6e03a45a8155e6daaf84c3ac",
      "0x3243b6d884f50c168e5abc4e777f5802fe5edc82ba7d60ac485c2c15a1f4ceef"
    ]
  },
  "0x11431690c8B941CA08c00BccF1dd223C9a42E14f": {
    "tokenId": 2,
    "proof": [
      "0x0b23fc24f78cdc0e41139f308798a8e2395ea7d0f7d4b5935ef2a581a1c2ea49",
      "0x79653b0fb2c40faeee138a82cc66add91b785c973187b73d754a9c4dff7bbf87",
      "0xdc5a67131eb2de9df2cb8377f3a4a0da7c8f669482e2b2f26d65ce5d9fbf5c52"
    ]
  },
  "0xB5b35a9E63eDEa2a43399632bcEf85ce879b29C1": {
    "tokenId": 1,
    "proof": [
      "0x21b55af6364fee7e0b01d66d8f10cdce2e4cc6069a8609f5301d1454d13c87f4",
      "0x79653b0fb2c40faeee138a82cc66add91b785c973187b73d754a9c4dff7bbf87",
      "0xdc5a67131eb2de9df2cb8377f3a4a0da7c8f669482e2b2f26d65ce5d9fbf5c52"
    ]
  }
}
  
export default merkelTree