import { createGlobalStyle } from "styled-components";
import { THEME } from "../config";
import HerokidLight from "../assets/fonts/Herokid-Light.otf";
import HerokidRegular from "../assets/fonts/Herokid-Regular.otf";
import HerokidSemiBold from "../assets/fonts/Herokid-SemiBold.otf";
import HerokidBoldWide from "../assets/fonts/Herokid-BoldWide.otf";
import HerokidExtraBoldWide from "../assets/fonts/Herokid-ExtraBoldWide.otf";

export const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Herokid';
    font-weight: 300;
    src: local('Herokid'), url(${HerokidLight}) format('truetype');
  }
  @font-face {
    font-family: 'Herokid';
    font-weight: 500;
    src: local('Herokid'), url(${HerokidRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Herokid';
    font-weight: 600;
    src: local('Herokid'), url(${HerokidSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'Herokid';
    font-weight: 800;
    src: local('Herokid'), url(${HerokidBoldWide}) format('truetype');
  }
  @font-face {
    font-family: 'Herokid';
    font-weight: 900;
    src: local('Herokid'), url(${HerokidExtraBoldWide}) format('truetype');
  }

  html{
    scroll-behavior: smooth;
  }

  body {
    margin: 0px;
    padding: 0px;
    font-family: 'Herokid', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    overflow-x: hidden;
  }

  #root{
    width: 100%;
    overflow-x: hidden;
  }

  p, h1, h2, h3, h4,h5 {
    margin-bottom: 0;
  }

  button { 
    cursor: pointer;
  }

  ul, li {
    list-style-type: none;
    margin: 0px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover{
    color: #3E62FE;
  }


  & .social-icons-popover{
    & .ant-popover-inner-content {
      padding: 0px !important;
    }
  }

  & .select-dropdown-class{
    background: ${THEME.colors.primary};
    box-shadow: 0px 2px 20px rgba(30, 44, 106, 0.08);
    border-radius: 8px;
    padding: 8px;
    [aria-selected="true"],[aria-selected="false"]{
      margin-bottom: 4px;
      border-radius: 8px;

      :last-child {
        margin-bottom: 0;
      }

      :hover{
        background: ${THEME.colors.interactiveSurfacePrimary.hover};  
      }
    }
    [aria-selected="true"]{
      background: ${THEME.colors.interactiveSurfacePrimary.active};
    }
  }

  .ant-notification-notice-error  {
    background: #FEE1E7;
  }

  .ant-notification-notice  {
    border-radius: 100px;
    padding: 16px;

    & .ant-notification-notice-message  {
      margin-bottom: 0 !important;
    }
  }

  & .ant-modal-content {
    background: #ffffff;
    padding: 0px 60px 30px 60px;
    border-radius: 8px;
    border: 10px solid #231f20;
  }

  .ant-modal-close-x {
    color: #231F20;
    margin-top: 10px;
    margin-right: 5px;
  }

  & .ant-modal-mask {
    background-color: rgba(256,256,256,.45)
  }

  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding-top: 110px;
    padding-bottom: 40px;
  }

  .journey-btn-group {
    display: flex;
    gap: 5px;
    position: relative;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  
  .journey-btn {
    width: 220px;
    background: #fad121;
    border-radius: 73px;
    background: #fad121;
    border-radius: 73px;
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    color: #231f20;
    padding: 10px 20px;
    padding-top: 15px;
    cursor: pointer;

    :disabled{
      background-color: grey !important;
    }
  }


  .mobile-warning-title{
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #FFFFFF;
  }

  .mobile-warning-text{
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    justify-content: center;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }

  .btn-outline {
    background-color: #231F20;
    border: 1px solid #fad121;
    color: #fad121;
    height: 50px;
  }

  .btn-cancel {
    background-color: #EB515D;
    color: #ffffff;
  }

  .btn-slice-left {
    border-radius: 0 10px 0 0;
    width: 150px;
  }

  .btn-slice-right {
    border-radius: 10px 0 0 0;
    background: #0094E3;
    width: 150px;
    color: #ffffff;
    border: none;
  }

  .modal-score {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    /* width: 100px; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .shadow-modal-container > .ant-modal-content {
    background-color: #231F20;
    border: 10px solid #000000;
  }

  .shadow-modal-container .ant-modal-close-x {
    color: #ffffff;
    font-size: 20px;
  }

  .modal-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-subtitle {
    font-family: 'Herokid';
    font-style: normal;
    font-size: 12px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    width: 70%;
    line-height: 139.9%;
    /* or 17px */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #231F20;
    margin: auto;
  }

  .shadow-modal-subtitle {
    color: #ffffff;
  }

  .modal-icon {
    position: absolute;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .modal-title {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;
    color: #231F20;
    background-color: #FAD121;
    width: 100%;
    position: absolute;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  .shadow-modal-title {
    background-color: #000000;
    color: #ffffff;
  }

  .text-white {
    color: white !important;
  }

  .modal-info-text {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
  }

  .journey-stake-group {
    display: flex;
    // gap: 40px;
  }

  .journey-stake {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .image-container {
      position: relative;
    }

    .badge {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 6px 6px 2px 6px;
      text-align: center;
      /* min-width: 36px; */
      width: 42px;
      height: 42px;
      justify-content: center;
      z-index: 2;
      border-radius: 50%;
      background: #383838;
      border: 2px solid #FFFFFF;

      font-family: 'Herokid';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;

      color: #FAD121;
    }

    img{
      :hover {
        cursor: pointer;
      }
    }
  }

  .journey-input-button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .stake-text {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 134.9%;
    /* or 27px */

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    color: #231F20;
  }

  .shadow-stake-text{
    color: #ffffff;
  }

  .ant-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .ant-col:first-child {
    padding-left: 0 !important;
  }

  .ant-col:last-child {
    padding-right: 0 !important;
  }

  .stake-helper-text {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    /* or 27px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #231F20;
  }

  .shadow-stake-helper-text{
    color: #ffffff;
  }

  .journey-stake-input-group {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  .journey-input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    background-color: #231F20;
    border-radius: 20px;
    padding: 20px;
    gap: 20px;
  }

  .shadow-journey-input-group{
    background-color: #383838;
  }

  .journey-input-text {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    align-self: flex-start;
  }

  .journey-input {
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    color: #383838;
  }

  .journey-info-text2{
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    word-break: break-word;
    color: #FAD121;
    width: 80%;
  }

  .journey-info-text3 {
    color: #ffffff;
    text-transform: inherit;
  }

  .modal-journey-btn-group {
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 5px;

    & .inactive {
      opacity: 0.44;
    }

    & .active {
      opacity: 1;
    }

    & .left-btn {
      border-radius: 73px 0px 0px 73px;
    }

    & .right-btn {
      border-radius: 0px 73px 73px 0px;
    }

    & .middle-btn {
      border-radius: 0px;
    }
  }

  .select-modal-journey-btn-group {
    display: flex;
    gap: 20px;
  }


  .journey-helper-text {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 159.4%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #6F6F6F;
  }

  .foot-note{
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 159.4%;
    /* or 22px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #6F6F6F;
  }

  .print-link {
    // font-family: 'Herokid';
    // font-style: normal;
    // font-weight: 400;
    // font-size: 16px;
    // line-height: 19px;
    text-decoration: underline;

    color: #0038FF;
    cursor: pointer;
    padding: 0px 4px;
  }

  .shadow-print-link {
    color: #fad121;
  }

  .puzzle-note {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 139.9%;
    /* or 17px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #231F20;
    padding-bottom: 5px;
  }

  .shadow-puzzle-note {
    color: white;
  }

  .connect-btn > div > button {
    font-family: 'Herokid' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 27px !important;
    color: #fad121 !important;
    border: 1px solid #fad121 !important;
    background-color: transparent !important;
    font-weight: bold !important;
    width: 160px !important;
    height: 44px !important;
    border-radius: 22px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-transform: uppercase !important;
    padding-top: 5px !important;
  }

  .connect-btn > div > button:hover {
    color: #fad121 !important;
    border: 1px solid #fad121 !important;
    
  }

  .connect-btn > div > button > div {
    background: transparent !important;
    border: none !important;
    color: #fad121 !important;
    font-family: 'Herokid' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 27px !important;
  }

  .contained-btn > div > button {
    background: #fad121 !important;
    border: none !important;
    color: #231f20 !important;
  }

  .contained-btn > div > button:hover {
    background: #fad121 !important;
    border: none !important;
    color: #231f20 !important;
  }

  ::placeholder {
    font-family: 'Herokid';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 159.4%;
    /* or 38px */

    color: #383838;
  }

  .tools {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .tools > button {
    background: #231f20 !important;
    border: none;
    color: white;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: bold
  }


  .ant-popover-arrow-content {
    --antd-arrow-background-color: #231f20;
  }

  .ant-popover {
    z-index: 70;
  }

  .ant-popover-inner {
    border-radius: 10px;
  }

  .ant-modal-mask {
    z-index: 1050;
  }

  .ant-modal-wrap {
    z-index: 1100;
  }

  .ant-popover-inner-content {
    background: #231f20;
    border-radius: 10px;


    & .journey-info {
      width: 250px;
      height: 230px;
      padding: 0px;
      background: #231f20;
      // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
  
    & .journey-info-text {
      font-family: "Herokid";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 147.6%;
      /* or 30px */
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #ffffff;
    }
  
    & .journey-title {
      font-family: "Herokid";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #eb515d;
    }
  
    & .journey-ends {
      font-family: "Herokid";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
  
    & .journey-countdown {
      margin-top: 10px;
    }
  
    & .journey-btn {
      background: #0094e3;
      border: none;
      border-radius: 73px;
      border-radius: 73px;
      font-family: "Herokid";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      padding: 10px 20px;
      padding-top: 15px;
    }

    &   .joined-btn {
      background: #FAD121;
      color: #231F20;
    }
  }

  .icon-box {
    position: absolute;
    top: -20%;
    right: -16px;
    background: #231f20;
    padding: 7px 20px 20px;
    border-radius: 12px;
  }
  .coolcats-icon-box {
    bottom: -20%;
    top: initial;
    padding: 12px 20px 10px 20px;
  }


  & .wallet-disconnect-popover  {
    & .ant-popover-message-title{
      color: #ffffff !important;
    }

    & .ant-popover-inner  {
      border-radius: 8px;
      box-shadow: 0px 2px 20px rgba(30, 44, 106, 0.08);
    }

      & .ant-popover-message {
        padding-top: 0px;
        > .anticon {
          display: none;
        }

        > .ant-popover-message-title  {
          ${THEME.typography.label1}
          padding: 0;
          text-align: left;
        }
      }

      & .ant-popover-buttons  {
        & button {
          border-radius: 100px;
          padding: 6px 16px;
          height: auto;
          border: 0;
          margin: 0 12px 0 0;

          ${THEME.typography.label1}

          :first-child {
            color: ${THEME.colors.contentPrimary};
            background: ${THEME.colors.interactive.surface.inverse.secondary.default};

            :hover  {
              background: ${THEME.colors.interactive.surface.inverse.secondary.hover};
            }

            :active {
              background: ${THEME.colors.interactive.surface.inverse.secondary.active};
            }
          }

          :nth-child(2) {
            color: ${THEME.colors.brand};
            margin: 0;
            background: ${THEME.colors.interactive.surface.brand.secondary.default};

            :hover  {
              background: ${THEME.colors.interactive.surface.brand.secondary.hover};
            }

            :active {
              background: ${THEME.colors.interactive.surface.brand.secondary.active};
            }
          }
        }
      }

    }
  }
`;
