import { Col, List, Modal, Row } from "antd";
import { useState } from "react";
import styled from "styled-components";
import {HEADER} from '../../../constants/images'

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isStaking: boolean;
  isDisabled: boolean;
  journeyTitle: string;
  activeJourney: null | number;
  journeys: any[];
}

export const ConfirmModal: React.FC<Props> = ({
  onSubmit,
  open,
  onCancel,
  isStaking,
  isDisabled,
  journeyTitle,
  activeJourney,
  journeys,
}) => {
  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      className={`modal-container ${
        activeJourney && journeys[activeJourney - 1]?.type === "shadow"
          ? "shadow-modal-container"
          : ""
      }`}
      width={900}
    >
      {activeJourney && journeys[activeJourney - 1]?.type === "shadow" && (
        <div className="modal-icon">
          <img src={HEADER.SHADOWLOGO} width="100px" />
        </div>
      )}
            <div className="modal-text">
        <div
          className={`modal-title ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-modal-title"
              : ""
          }`}
        >
          {activeJourney &&
            `${
              activeJourney &&
              journeys[activeJourney - 1]?.type === "shadow" ?
              "Chapter 2," : ""
            } Journey #${journeys[activeJourney - 1]?.number}: ${
              journeys[activeJourney - 1]?.title
            }`}
        </div>
      </div>
      <HeaderWrapper>
        <div className={`confirm-header ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-confirm-header"
              : ""
          }`}>CONFIRM STAKING</div>
        <div className={`confirm-description ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-confirm-description"
              : ""
          }`}>
          Signing the message below confirms soft-staking of the assets
          previously selected.
        </div>
        <div className="journey-confirm-btn-group">
          <div className={`confirm-description2 ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-confirm-description2"
              : ""
          }`}>
            I understand that if the assets are sold or transferred prior to the
            end of the Journey, I will no longer be eligible for Journey
            Rewards.
          </div>
          <button
            className="journey-btn confirm-journey-btn"
            onClick={onSubmit}
            disabled={isStaking || isDisabled}
          >
            Sign
          </button>
        </div>

        <div className="confirm-warning">
          Note: Signature does not remove or transfer assets from your wallet
          and will not cost any gas to complete.
        </div>
      </HeaderWrapper>
    </Modal>
  );
};

const HeaderWrapper = styled.div`
  .journey-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .confirm-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 12px;
    color: #231f20;
  }

  .confirm-description {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #231f20;

    margin-bottom: 28px;
  }



  .confirm-description2 {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #231f20;
  }

  .shadow-confirm-header, .shadow-confirm-description, .shadow-confirm-description2 {
    color: #ffffff
  }

  .confirm-warning {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 18px;
    color: #6f6f6f;
  }

  .journey-confirm-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
  }

  .confirm-journey-btn {
    background-color: #0094e3;
    color: #ffffff;
    border: none;
  }
`;
