import styled from "styled-components";
import { deviceWidth, THEME } from "../../../config";
import { Link } from "react-router-dom";
import { socialContentData } from "../../../constants";

import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter.svg";
// import { ReactComponent as ChainIcon } from "../../../assets/icons/blockchain/chain1.svg";
import FooterBG from "../../../assets/images/footer-bg.png";
import { FOOTER } from "../../../constants/images";

export const Footer = () => {
  return (
    <FooterWrapper>
      <div className="container">
        <div className="footer-logo">
          <img src={FOOTER.LOGO} />
        </div>
        <div className="footer-text">
          <div className="subtitle">Don’t miss out on what’s new,</div>
          <div className="title">
            CONNECT <br></br> WITH US!
          </div>
          <div></div>
        </div>
        <SocialContentWrapper>
          {socialContentData.map(({ Icon, title, link }, idx) => (
            <a
              className="social"
              key={`social_${idx}`}
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              <Icon />
            </a>
          ))}
        </SocialContentWrapper>
        <div className="footer-links">
          <a
            className="link"
            href="https://coolcatsnft.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
          <a
            className="link"
            href="https://meowpad.coolcatsnft.com/"
            target="_blank"
            rel="noreferrer"
          >
            Meowpad
          </a>
          <a
            className="link"
            href="https://coolcatsnft.com/about"
            target="_blank"
            rel="noreferrer"
          >
            About
          </a>
          <a
            className="link"
            href="https://coolcatsnft.com/contact"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
        </div>
        <div className="footer-copyright">© Cool Cats Group LLC</div>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  width: 100%;
  background-image: url(${FooterBG});
  background-size: cover;
  z-index: 1;
  position: relative;

  .container {
    width: 80%;
    padding: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  @media (max-width: 768px) {
    .container {
      width: 100%;
      padding: 20px;
      gap: 20px;
    }
  }

  .footer-logo {
    display: flex;
    justify-content: center;

    img {
      width: auto;
      height: 90px;
    }
  }

  @media (max-width: 768px) {
    .footer-logo {
      img {
        width: auto;
        height: 80px;
      }
    }
  }

  .footer-text > .subtitle {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #fad121;
  }


  .footer-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: auto;
  }

  @media (max-width: 768px) {
    .footer-text {
      width: 100%;
    }

    .footer-text > .subtitle {
      font-size: 18px;
      line-height: 1;
    }
  }

  .footer-text > .title {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 900;
    font-size: 74px;
    line-height: 66px;
    /* or 83px */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
  }

  @media (max-width: 768px) {
    .footer-text > .title {
      font-size: 48px;
      line-height: 48px;
      margin-top: 8px;
    }
  }

  .footer-links {
    display: flex;
    justify-content: center;
  }

  .footer-links > .link {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    padding: 0px 30px;
    text-transform: uppercase;
    border-right: 1px solid #ffffff;
    cursor: pointer;

    :hover {
      color: #fad121;
    }
  }

  @media (max-width: 768px) {
    .footer-links {
      flex-wrap: wrap;
      gap: 0px;
    }

    .footer-links > .link {
      font-size: 14px;
      padding: 0px 10px;
    }
  }

  .footer-links > .link:last-child {
    border-right: none;
  }

  .footer-copyright {
    display: flex;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #c7c7c7;
  }
`;

const SocialContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  & .social {
    display: flex;
    align-items: center;
    padding: 12px 18px;
    border-radius: 8px;
    cursor: pointer;

    & > span {
      ${THEME.typography.body3}
      margin-left: 16px;
    }
    & > svg {
      width: 24px;
      height: 24px;

      path {
        fill: #85c7ee;
      }
    }
  }
  & :hover {
    color: #fad121;
  }

  @media (max-width: 768px) {
    & .social {
      padding: 6px 9px;
    }
  }
`;
