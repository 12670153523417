import { Modal } from "antd";
import { Box } from "@mui/material";
import { HOMEPAGE, HEADER } from "../../../constants/images";
import { CoolScore, JourneyScore, MaxScore } from "../../molecules/Score";
import { TokenType } from "../../../services/api";
import { getCount, getRandom } from "../../../helpers/stakeSelectors";
import { ScoreContext } from "../../../hoc/ScoreProvider";
import { useContext } from "react";

interface Props {
  isStakeModalOpen: boolean;
  handleStakeModalCancel: () => void;
  activeJourney: null | number;
  path: string;
  setPath: (state: any) => void;
  selectAll: () => void;
  setSelectionModal: (state: any) => void;
  handleStakeModalBack: () => void;
  setIsConfirmationModalOpen: (state: boolean) => void;
  address: any;
  journeys: any[];
}

export const StakeModal: React.FC<Props> = ({
  isStakeModalOpen,
  handleStakeModalCancel,
  activeJourney,
  path,
  setPath,
  selectAll,
  setSelectionModal,
  handleStakeModalBack,
  setIsConfirmationModalOpen,
  address,
  journeys,
}) => {
  const {
    coolScore,
    journeyScore,
    selectedCats,
    selectedPets,
    selectedCooltopia,
    catsData,
    petsData,
    cooltopiaData,
    wolvesData,
    setWolvesData,
    selectedWolves,
    setSelectedWolves,
    answer,
    setAnswer,
  } = useContext(ScoreContext);

  return (
    <Modal
      centered
      open={isStakeModalOpen}
      onCancel={handleStakeModalCancel}
      footer={null}
      className={`modal-container ${
        activeJourney && journeys[activeJourney - 1]?.type === "shadow"
          ? "shadow-modal-container"
          : ""
      }`}
      width={1100}
    >
      {activeJourney && journeys[activeJourney - 1]?.type === "shadow" && (
        <div className="modal-icon">
          <img src={HEADER.SHADOWLOGO} width="100px" />
        </div>
      )}
      <div className="modal-text">
        <div
          className={`modal-title ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-modal-title"
              : ""
          }`}
        >
          {activeJourney &&
            `${
              activeJourney &&
              journeys[activeJourney - 1]?.type === "shadow" ?
              "Chapter 2," : ""
            } Journey #${journeys[activeJourney - 1]?.number}: ${
              journeys[activeJourney - 1]?.title
            }`}
        </div>
      </div>
      <div
        className="journey-stake-input-group"
        // style={{ gap: 100 }}
      >
        <div
          className={`journey-input-group ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-journey-input-group"
              : ""
          }`}
        >
          <div className="journey-input-text">
            {activeJourney && `${journeys[activeJourney - 1]?.question}`}
          </div>
          <input
            type="text"
            className="journey-input"
            placeholder="Answer..."
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
          <p className="journey-info-text2">
            GET IT RIGHT AND You’ll gain +300 JOURNEY Bonus
            {/* Complete the slide puzzle to gain +300 Journey Bonus */}
          </p>
          <p
            className="journey-info-text2 journey-info-text3"
            style={{ justifyContent: "center" }}
          >
            {activeJourney && `${journeys[activeJourney - 1]?.pathQuestion}`}
          </p>
          {activeJourney &&
            <div className="modal-journey-btn-group">
              <div
                className={`journey-btn left-btn ${path === journeys[activeJourney - 1]?.leftButton ? "active" : "inactive"
                  }`}
                onClick={() =>
                  setPath((prev: any) =>
                    prev === journeys[activeJourney - 1]?.leftButton ? "" : journeys[activeJourney - 1]?.leftButton
                  )
                }
              >
                {journeys[activeJourney - 1]?.leftButton}
              </div>
              {/* <div
              className={`journey-btn middle-btn ${
                path === "Not Really" ? "active" : "inactive"
              }`}
              onClick={() =>
                setPath((prev: any) =>
                  prev === "Not Really" ? "" : "Not Really"
                )
              }
            >
              Not Really
            </div>
            <div
              className={`journey-btn middle-btn ${
                path === "Kind Of" ? "active" : "inactive"
              }`}
              onClick={() =>
                setPath((prev: any) =>
                  prev === "Kind Of" ? "" : "Kind Of"
                )
              }
            >
              Kind Of
            </div> */}
              <div
                className={`journey-btn right-btn ${path === journeys[activeJourney - 1]?.rightButton ? "active" : "inactive"
                  }`}
                onClick={() =>
                  setPath((prev: any) =>
                    prev === journeys[activeJourney - 1]?.rightButton ? "" : journeys[activeJourney - 1]?.rightButton
                  )
                }
              >
                {journeys[activeJourney - 1]?.rightButton}
              </div>
            </div>
          }
          <p className="journey-helper-text">Select one to continue </p>
        </div>
        <div className="journey-input-button-group">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div className="journey-btn btn-outline" onClick={selectAll}>
              Select All
            </div>
            {activeJourney && journeys[activeJourney - 1]?.type === "shadow" ? (
              <div className="journey-stake-group">
                <div className="journey-stake" style={{ width: "500px" }}>
                  <div className="image-container">
                    <img
                      src={HOMEPAGE.NFTPLACEHOLDER}
                      width="200px"
                      onClick={() => setSelectionModal(TokenType.Wolves)}
                      alt={TokenType.Wolves}
                    />
                    <div className="badge">
                      {getCount(wolvesData, selectedWolves)}
                    </div>
                    {getCount(wolvesData, selectedWolves) > 0 && (
                      <img
                        src={`https://avatar.coolcatsnft.com/shadowwolf/${
                          getRandom(selectedWolves) || wolvesData[0]?.id
                        }.png`}
                        width="175px"
                        height={"218px"}
                        style={{
                          position: "absolute",
                          left: "12.5px",
                          top: "15px",
                          objectFit: "cover",
                          borderRadius: "16px",
                        }}
                        onClick={() => setSelectionModal(TokenType.Wolves)}
                        alt={TokenType.Wolves}
                      />
                    )}
                  </div>
                  <div className="stake-text shadow-stake-text">
                    Shadow Wolves
                    <br></br>
                    <div className="stake-helper-text shadow-stake-helper-text">
                      +75 FIRST WOLF<br></br>10% LESS EACH ADDITIONAL
                    </div>
                  </div>
                </div>
                
              </div>
            ) : (
              <div className="journey-stake-group">
                <div className="journey-stake" style={{ marginRight: 40 }}>
                  <div className="image-container">
                    <img
                      src={HOMEPAGE.NFTPLACEHOLDER}
                      width="200px"
                      onClick={() => setSelectionModal(TokenType.Cats)}
                      alt={TokenType.Cats}
                    />
                    <div className="badge">
                      {getCount(catsData, selectedCats)}
                    </div>
                    {getCount(catsData, selectedCats) > 0 && (
                      <img
                        src={`https://avatar.coolcatsnft.com/cat/${
                          getRandom(selectedCats) || catsData[0]?.id
                        }.png`}
                        width="175px"
                        height={"218px"}
                        style={{
                          position: "absolute",
                          left: "12.5px",
                          top: "15px",
                          objectFit: "cover",
                          borderRadius: "16px",
                        }}
                        onClick={() => setSelectionModal(TokenType.Cats)}
                        alt={TokenType.Cats}
                      />
                    )}
                  </div>
                  <div className="stake-text">
                    COOL CATS
                    <br></br>
                    <div className="stake-helper-text">
                      +100 PER CAT<br></br>5 MAX
                    </div>
                  </div>
                </div>
                <div className="journey-stake">
                  <div className="image-container">
                    <img
                      src={HOMEPAGE.NFTPLACEHOLDER}
                      width="175px"
                      onClick={() => setSelectionModal(TokenType.Pets)}
                      alt={TokenType.Pets}
                    />
                    <div className="badge">
                      {getCount(petsData, selectedPets)}
                    </div>
                    {getCount(petsData, selectedPets) > 0 && (
                      <img
                        src={`https://avatar.coolcatsnft.com/pet/${
                          getRandom(selectedPets) || petsData[0]?.id
                        }.png`}
                        width="150px"
                        height={"189px"}
                        style={{
                          position: "absolute",
                          left: "12.5px",
                          top: "15px",
                          objectFit: "cover",
                          borderRadius: "16px",
                        }}
                        onClick={() => setSelectionModal(TokenType.Pets)}
                        alt={TokenType.Pets}
                      />
                    )}
                  </div>
                  <div className="stake-text">
                    COOL PETS
                    <br></br>
                    <div className="stake-helper-text">
                      +50 PER PET<br></br>6 MAX
                    </div>
                  </div>
                </div>
                <div className="journey-stake">
                  <div className="image-container">
                    <img
                      src={HOMEPAGE.NFTPLACEHOLDER}
                      width="175px"
                      height="140px"
                      onClick={() => setSelectionModal(TokenType.Cooltopia)}
                      alt={TokenType.Cooltopia}
                    />
                    <div className="badge" style={{ right: 30 }}>
                      {getCount(cooltopiaData, selectedCooltopia)}
                    </div>
                    {getCount(cooltopiaData, selectedCooltopia) > 0 && (
                      <img
                        // src={`https://avatar.coolcatsnft.com/cooltopia/${
                        //   selectedCooltopia[0] || cooltopiaData[0]?.id
                        // }.png`}
                        src={`https://metadata.coolcatsnft.com/item/image/${Math.floor(
                          (selectedCooltopia[0] || cooltopiaData[0]?.id) / 1000
                        )}${
                          Math.floor(
                            (selectedCooltopia[0] || cooltopiaData[0]?.id) /
                              1000
                          ) === 77
                            ? ".png"
                            : ".gif"
                        }`}
                        width="95px"
                        height="120px"
                        style={{
                          position: "absolute",
                          left: "40.5px",
                          top: "11px",
                          objectFit: "cover",
                          borderRadius: "16px",
                        }}
                        onClick={() => setSelectionModal(TokenType.Cooltopia)}
                        alt={TokenType.Cooltopia}
                      />
                    )}
                  </div>
                  <div className="stake-text">
                    Kritten Boss
                    <br></br>
                    <div
                      className="stake-helper-text"
                      // style={{ visibility: "hidden" }}
                    >
                      +300-500<br></br>1 MAX
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Box display="flex" alignItems={"center"}>
            <div>
              <CoolScore score={coolScore} address={address} modal />
              <JourneyScore score={journeyScore} address={address} modal />
            </div>
            <MaxScore
              score={coolScore + journeyScore}
              address={address}
              modal
            />
          </Box>
        </div>
      </div>

      <div className="journey-btn-group">
        <div
          className="journey-btn btn-cancel btn-slice-left"
          onClick={handleStakeModalBack}
        >
          Back
        </div>

        <button
          className="journey-btn btn-slice-right"
          onClick={() => {
            // if (
            //   selectedCats.length === 0 &&
            //   selectedPets.length === 0 &&
            //   selectedFractures.length === 0
            // )
            //   return;
            // setAnswer(tempAnswer);
            setIsConfirmationModalOpen(true);
          }}
          disabled={path === "" || path === null || path === undefined}
        >
          Continue
        </button>
      </div>

      {/* <p className="foot-note">
                    *Applied Journey Score goes down after the first fracture
                  </p> */}
    </Modal>
  );
};
