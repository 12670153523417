import { useEffect, useState } from "react";
interface IScreenDetectorReturn {
  isWebBrowser: boolean;
}
export function useScreenDetector(): IScreenDetectorReturn {
  const [isWebBrowser, setIsWebBrowser] = useState(true);

  useEffect(() => {
    const UA = navigator.userAgent;
    const hasTouchScreen =
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
      /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    setIsWebBrowser(!hasTouchScreen);

    // return () => {
    //   setIsWebBrowser(false);
    // };
  }, []);

  //   useEffect(() => {
  //     if (!isWebBrowser) {
  //       snackBarInfo('Please use web browser for better experience on our site!');
  //     } else {
  //       //console.log("You're in web browser!");
  //     }
  //   }, [isWebBrowser, snackBarInfo]);

  return {
    isWebBrowser,
  };
}
