import React, { useState, useEffect, useRef } from "react";
import shadowJourneys from "src/constants/shadowJourneys";
import coolcatsJourneys from "src/constants/journeys";
import { Popover } from "antd";
import { HOMEPAGE } from "src/constants/images";
import JourneyCard from "../JourneyCard";
import { isJourneyActive, isJourneyLocked } from "src/helpers/journey";
import LineTo from "react-lineto";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface Props {
  upcomingJourney: number;
  activeJourney: number | null;
  loading: boolean;
  setIsLoading: (state: boolean) => void;
  journeyParticipated: boolean;
  journeyEnded: boolean;
  showModal: (journey: number) => void;
  setIsCompleteModalOpen: (state: boolean) => void;
  handleJourneyPopoverOpenChange: (journey: number) => void;
  journeyPopoverOpen: number;
  journeys: any[];
}

export const MapComponent: React.FC<Props> = ({
  upcomingJourney,
  activeJourney,
  loading,
  setIsLoading,
  journeyParticipated,
  journeyEnded,
  showModal,
  setIsCompleteModalOpen,
  handleJourneyPopoverOpenChange,
  journeyPopoverOpen,
  journeys,
}) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [movement, setMovement] = useState<boolean>(false);

  // useRef allows us to "store" the div in a constant,
  // and to access it via observedDiv.current
  const observedDiv = useRef<HTMLDivElement>(null);

  const handleElementResized = () => {
    if (observedDiv.current) {
      if (observedDiv.current.offsetWidth !== width) {
        setWidth(observedDiv.current.offsetWidth);
      }
      if (observedDiv.current.offsetHeight !== height) {
        setHeight(observedDiv.current.offsetHeight);
      }
    }
  };

  // we also instantiate the resizeObserver and we pass
  // the event handler to the constructor
  const resizeObserver = new ResizeObserver(handleElementResized);

  useEffect(() => {
    // the code in useEffect will be executed when the component
    // has mounted, so we are certain observedDiv.current will contain
    // the div we want to observe
    if (observedDiv.current) {
      resizeObserver.observe(observedDiv.current);
    }

    // if useEffect returns a function, it is called right before the
    // component unmounts, so it is the right place to stop observing
    // the div
    return function cleanup() {
      resizeObserver.disconnect();
    };
  });

  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={200}
      initialPositionY={100}
      onPanningStart={() => setMovement(true)}
      onZoomStart={() => setMovement(true)}
      onWheelStart={() => setMovement(true)}
      onPinchingStart={() => setMovement(true)}
      onPanningStop={() => setMovement(false)}
      onZoomStop={() => setMovement(false)}
      onWheelStop={() => setMovement(false)}
      onPinchingStop={() => setMovement(false)}
      onTransformed={() => {
        setMovement(true);
        setMovement(false);
      }}
      centerOnInit
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <div className="tools">
            <button
              onClick={() => {
                zoomIn();
              }}
              style={{ borderRadius: "8px 8px 0px 0px" }}
            >
              +
            </button>
            <button
              onClick={() => {
                zoomOut();
              }}
              style={{ borderRadius: "0px 0px 8px 8px" }}
            >
              -
            </button>
            {/* <button onClick={() => resetTransform()}>x</button> */}
          </div>
          <TransformComponent>
            <div className="imgContainer" ref={observedDiv}>
              <img
                src={HOMEPAGE.BG}
                width="100%"
                height="100%"
                alt="map"
                style={{ objectFit: "cover" }}
              />

              {coolcatsJourneys.map((journey, index) => {
                return (
                  index > 4 && (
                    <div key={index}>
                      {!isJourneyLocked(journey.startDate) ||
                      journey.number === upcomingJourney ? (
                        <Popover
                          key={index}
                          placement="top"
                          content={
                            activeJourney && (
                              <JourneyCard
                                loading={loading}
                                setIsLoading={setIsLoading}
                                journey={journey}
                                journeyParticipated={journeyParticipated}
                                journeyEnded={journeyEnded}
                                showModal={showModal}
                                setIsCompleteModalOpen={setIsCompleteModalOpen}
                                activeJourney={activeJourney}
                              />
                            )
                          }
                          trigger={
                            isJourneyActive(journey.startDate, journey.endDate) || journey.number === upcomingJourney
                              ? "click"
                              : "hover"
                          }
                          // open={journeyPopoverOpen === journey.number && !movement}
                          // open={
                          //   journeyPopoverOpen
                          //     ? journeyPopoverOpen === journey.number &&
                          //     !movement
                          //     : isJourneyActive(
                          //       journey.startDate,
                          //       journey.endDate
                          //     )
                          //       ? isJourneyActive(
                          //         journey.startDate,
                          //         journey.endDate
                          //       ) && !movement
                          //       : undefined
                          // }
                          // open={
                          //   isJourneyActive(journey.startDate, journey.endDate) || journey.number === upcomingJourney
                          //     ? (isJourneyActive(
                          //         journey.startDate,
                          //         journey.endDate
                          //       ) || journey.number === upcomingJourney) && !movement
                          //     : undefined
                          // }
                          open={false}
                          onOpenChange={() => {
                            handleJourneyPopoverOpenChange(journey.number);
                          }}
                        >
                          <div
                            className={`quest-container ${
                              journey.type === "shadow"
                                ? `quest-container-shadow-${journey.number}`
                                : `quest-container-${journey.number}`
                            }`}
                            style={{
                              top: `${journey.markerPosition.top}%`,
                              right: `${journey.markerPosition.right}%`,
                            }}
                          >
                            <div
                              className="marker"
                              style={{
                                backgroundImage: `url(${
                                  journeyPopoverOpen === journey.number
                                    ? HOMEPAGE.QUESTACTIVEICON
                                    : HOMEPAGE.QUESTICON
                                })`,
                              }}
                            >
                              <div className="quest-number">
                                {journey.number}
                              </div>
                            </div>
                          </div>
                        </Popover>
                      ) : (
                        <div
                          className="quest-container"
                          style={{
                            top: `${journey?.markerPosition?.top}%`,
                            right: `${journey?.markerPosition?.right}%`,
                          }}
                        >
                          <div
                            className="marker lock-icon"
                            style={{
                              backgroundImage: `url(${HOMEPAGE.QUESTLOCKICON})`,
                            }}
                          ></div>
                        </div>
                      )}
                      {/* {journey.number !== 1 && (
                        <LineTo
                          to={
                            journey.type === "shadow"
                              ? `quest-container-shadow-${journey.number}`
                              : `quest-container-${journey.number}`
                          }
                          from={
                            journey.type === "shadow"
                              ? `quest-container-shadow-${journey.number - 2}`
                              : `quest-container-${journey.number - 2}`
                          }
                          borderStyle="dashed"
                          borderWidth={5}
                          borderColor="#fa2121"
                          zIndex={0}
                          fromAnchor="left"
                          toAnchor="right"
                        />
                      )} */}
                    </div>
                  )
                );
              })}

              {/* {shadowJourneys.map((journey, index) => {
                  return (
                    <>
                      {!isJourneyLocked(journey.startDate) ||
                      journey.number === upcomingJourney ? (
                        <Popover
                          key={index}
                          placement="top"
                          content={
                            activeJourney && (
                              <JourneyCard
                                loading={loading}
                                setIsLoading={setIsLoading}
                                journey={journey}
                                journeyParticipated={journeyParticipated}
                                journeyEnded={journeyEnded}
                                showModal={showModal}
                                setIsCompleteModalOpen={setIsCompleteModalOpen}
                                activeJourney={activeJourney}
                              />
                            )
                          }
                          trigger="click"
                          open={journeyPopoverOpen === journey.number && !movement}
                          onOpenChange={() =>
                            handleJourneyPopoverOpenChange(journey.number)
                          }
                        >
                          <div
                            className={`quest-container ${`quest-container-${journey.number}`}`}
                            style={{
                              top: `${journey.markerPosition.top}%`,
                              right: `${journey.markerPosition.right}%`,
                            }}
                          >
                            <div
                              className="marker"
                              style={{
                                backgroundImage: `url(${
                                  journeyPopoverOpen === journey.number
                                    ? HOMEPAGE.QUESTACTIVEICON
                                    : HOMEPAGE.QUESTICON
                                })`,
                              }}
                            >
                              <div className="quest-number">{journey.number}</div>
                            </div>
                          </div>
                        </Popover>
                      ) : (
                        // <div
                        //   className="quest-container"
                        //   style={{
                        //     top: `${journey?.markerPosition?.top}%`,
                        //     right: `${journey?.markerPosition?.right}%`,
                        //   }}
                        // >
                        //   <div
                        //     className="marker lock-icon"
                        //     style={{
                        //       backgroundImage: `url(${HOMEPAGE.QUESTLOCKICON})`,
                        //     }}
                        //   ></div>
                        // </div>
                        <Popover
                          key={index}
                          placement="top"
                          content={
                            activeJourney && (
                              <JourneyCard
                                loading={loading}
                                setIsLoading={setIsLoading}
                                journey={journey}
                                journeyParticipated={journeyParticipated}
                                journeyEnded={journeyEnded}
                                showModal={showModal}
                                setIsCompleteModalOpen={setIsCompleteModalOpen}
                                activeJourney={activeJourney}
                              />
                            )
                          }
                          trigger="click"
                          // open={journeyPopoverOpen === journey.number}
                          open={!movement}
                          // onOpenChange={() =>
                          //   handleJourneyPopoverOpenChange(journey.number)
                          // }
                        >
                          <div
                            className={`quest-container ${`quest-container-${journey.number}`}`}
                            style={{
                              top: `${journey.markerPosition.top}%`,
                              right: `${journey.markerPosition.right}%`,
                            }}
                          >
                            <div
                              className="marker"
                              style={{
                                backgroundImage: `url(${HOMEPAGE.QUESTLOCKICON})`,
                              }}
                            ></div>
                          </div>
                        </Popover>
                      )}
                    </>
                  );
                })} */}
            </div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};
