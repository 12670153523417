import { Web3ReactProvider } from '@web3-react/core';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { MetaMaskProvider } from '../hoc/Metamask';
import { ScoreProvider } from '../hoc/ScoreProvider';

import Web3 from 'web3';
import Home from '../components/organisms/Home';
import RewardPage from '../components/organisms/Reward';
import AchievementTransfer from '../components/organisms/AchievementTransfer';
import { LayoutProvider } from '../hoc/LayoutProvider';
import routes from '../constants/routes';
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from '@web3modal/ethereum';

import { useWeb3ModalTheme, Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon, polygonMumbai } from 'wagmi/chains';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { useScreenDetector } from 'src/hooks';
import CustomAvatar from '../components/atoms/CustomAvatar';

//Custom Wallets
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  coinbaseWallet,
  metaMaskWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

//Rainbow Kit
import '@rainbow-me/rainbowkit/styles.css';

import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { publicProvider } from 'wagmi/providers/public';

const chains = [mainnet];
const projectId = 'ab8368fdd80ebc31dc022e9b59808cfa';

// Wagmi client
const { publicClient } = configureChains(chains, [publicProvider()]);

const connectors = connectorsForWallets([
  {
    groupName: 'Wagmi',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ appName: 'Wagmi', chains }),
      walletConnectWallet({ projectId, chains }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

// Web3Modal Ethereum Client
// const ethereumClient = new EthereumClient(wagmiClient, chains);

function getLibrary(provider: any, connector: any) {
  console.log('Provider', provider);
  return new Web3(provider);
}

const Routing = () => {
  return (
    <>
      <WagmiConfig config={config}>
        <RainbowKitProvider chains={chains} avatar={CustomAvatar}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <MetaMaskProvider>
              <ScoreProvider>
                <BrowserRouter>
                  <LayoutProvider>
                    <Routes>
                      <Route path={routes.HOMEPAGE} element={<Home />} />
                      <Route path='/reward' element={<RewardPage />} />
                      <Route
                        path='/transfer'
                        element={<AchievementTransfer />}
                      />
                    </Routes>
                  </LayoutProvider>
                </BrowserRouter>
              </ScoreProvider>
            </MetaMaskProvider>
          </Web3ReactProvider>
        </RainbowKitProvider>
      </WagmiConfig>

      {/* <Web3Modal
        ethereumClient={ethereumClient}
        projectId={projectId}
        themeMode={"dark"}
        themeColor={"purple"}
        themeBackground={"themeColor"}
        // walletImages={{
        //   metaMask:
        //     "https://explorer-api.walletconnect.com/v2/logo/lg/619537c0-2ff3-4c78-9ed8-a05e7567f300?projectId=8e6b5ffdcbc9794bf9f4a1952578365b",
        // }}
        // mobileWallets={[
        //   {
        //     id: "metaMask",
        //     name: "MetaMask",
        //     links: {
        //       native: "metamask",
        //       universal: "https://metamask.app.link",
        //     },
        //   },
        // ]}
        // desktopWallets={[
        //   {
        //     id: "metaMask",
        //     name: "MetaMask",
        //     links: {
        //       native: "metamask",
        //       universal: "https://metamask.app.link",
        //     },
        //   },
        // ]}
        explorerAllowList={[
          // metamask
          "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
        ]}
      /> */}
    </>
  );
};

export default Routing;
