import styled from "styled-components";
import { HOMEPAGE } from "src/constants/images";
interface Props {
  score: number;
  address: undefined | null | string;
  modal?: boolean;
}

export const CoolScore: React.FC<Props> = ({ score, address, modal }) => {
  return address ? (
    <>
      <HeaderWrapper style={{ width: modal ? "185px" : "200px", zIndex : modal ? 1 : 0 }}>
        {/* <span className="small" style={{ visibility: "hidden" }}>
          CS
        </span> */}
        <div className="icon-container">
          <img src={HOMEPAGE.COOLSCOREICON} width="40px" />
        </div>
        <div className="score-group">
          <div className="scoreText">{address ? score : "-"} </div>
          {modal && <div className="small"> COOL Score</div>}
        </div>
      </HeaderWrapper>
    </>
  ) : (
    <></>
  );
};

export const JourneyScore: React.FC<Props> = ({ score, address, modal }) => {
  return address ? (
    <>
      <HeaderWrapper style={{ backgroundColor: "#0094E3", width: modal ? "185px" : "175px", zIndex : modal ? 1 : 0 }}>
        <div className="icon-container">
          <img src={HOMEPAGE.JOURNEYSCOREICON} width="40px" />
        </div>
        <div className="score-group">
          <div className="scoreText">{address ? `+${score}` : "-"}{" "} </div>
          {modal && <div className="small"> Journey Bonus</div>}
        </div>
      </HeaderWrapper>
    </>
  ) : (
    <></>
  );
};

export const MaxScore: React.FC<Props> = ({ score, address, modal }) => {
  return address ? (
    <>
      <HeaderWrapper style={{ backgroundColor: "#EB515D", width: "175px", justifyContent: "flex-end", color: "white", left: -40 }}>
        {/* <div className="icon-container">
          <img src={HOMEPAGE.JOURNEYSCOREICON} width="40px" />
        </div> */}
        <div className="score-group">
          <div className="scoreText">{address ? `${score}` : "-"}{" "} </div>
          {modal && <div className="small" style={{ color: "white" }}><strong style={{ marginRight: 2 }}>Max</strong>  Journey Score</div>}
        </div>
      </HeaderWrapper>
    </>
  ) : (
    <></>
  );
};

const HeaderWrapper = styled.div`
  background: #fad121;
  border-radius: 10px;
  height: 70px;
  font-family: "Herokid";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 8px;
  position: relative;
  width: 200px;

  .score-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span {
    margin-top: 4px;
    font-weight: bolder;
    padding: 0px 2px;
  }

  .small {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }

  .scoreText {
    text-align: center;

    min-width: 74.5px;
  }

  .icon-container {
    background: #2e2b2c;
    border-radius: 12px;
    padding: 10px;
  }
`;
