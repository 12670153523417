import { Modal } from "antd";
import Zoom from "react-medium-image-zoom";
import { HEADER } from "../../../constants/images";
import { Carousel } from "react-responsive-carousel";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
interface Props {
  isModalOpen: boolean;
  handleCancel: () => void;
  activeJourney: null | number;
  handlePuzzleModal: () => void;
  journeys: any[];
  setDisableStakeContinueButton: (state: boolean) => void;
  disableStakeContinueButton: boolean;
}

export const ContentModal: React.FC<Props> = ({
  isModalOpen,
  handleCancel,
  activeJourney,
  handlePuzzleModal,
  journeys,
  setDisableStakeContinueButton,
  disableStakeContinueButton
}) => {
  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      className={`modal-container ${
        activeJourney && journeys[activeJourney - 1]?.type === "shadow"
          ? "shadow-modal-container"
          : ""
      }`}
      width={900}
      // afterClose={() => {
      //   if (
      //     activeJourney &&
      //     journeys[activeJourney - 1]?.mediaType === "video"
      //   ) {
      //     const iframe = document.querySelector("iframe") as any;
      //     iframe.src = `${journeys[activeJourney - 1]?.link}`;
      //   }
      // }}
    >
      {activeJourney && journeys[activeJourney - 1]?.type === "shadow" && (
        <div className="modal-icon">
          <img src={HEADER.SHADOWLOGO} width="100px" />
        </div>
      )}
      <div className="modal-text">
        <div
          className={`modal-title ${
            activeJourney && journeys[activeJourney - 1]?.type === "shadow"
              ? "shadow-modal-title"
              : ""
          }`}
        >
          {activeJourney &&
            `${
              activeJourney && journeys[activeJourney - 1]?.type === "shadow"
                ? "Chapter 2,"
                : ""
            } Journey #${journeys[activeJourney - 1]?.number}: ${
              journeys[activeJourney - 1]?.title
            }`}
        </div>
        {activeJourney &&
          journeys[activeJourney - 1]?.contentCopy.map(
            (copy: string, index: number, array: string[]) => {
              return (
                <>
                  <div
                    key={index}
                    className={`modal-subtitle ${
                      journeys[activeJourney - 1]?.type === "shadow"
                        ? "shadow-modal-subtitle"
                        : ""
                    }`}
                  >
                    {copy}
                  </div>
                  {index !== array.length - 1 && <br />}
                </>
              );
            }
          )}
      </div>

      <div>
        {activeJourney &&
          (journeys[activeJourney - 1]?.mediaType === "image" ? (
            <Zoom>
              <img src={journeys[activeJourney - 1]?.links[0]} width="539px" />
            </Zoom>
          ) : journeys[activeJourney - 1]?.mediaType === "carousel" ? (
            <div style={{ position: "relative", width: "500px" }}>
              {activeJourney && (
                <Carousel
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={onClickHandler}
                        sx={{
                          backgroundColor: "#231f20",
                          color: "#ffffff",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                          top: "50%",
                          right: "0px",
                          opacity: 0.7,
                          transition: "opacity 0.3s",
                          "&:hover": {
                            backgroundColor: "#231f20",
                            color: "#ffffff",
                            opacity: 1,
                          },
                        }}
                      >
                        <ChevronRightIcon />
                      </IconButton>
                    )
                  }
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={onClickHandler}
                        sx={{
                          backgroundColor: "#231f20",
                          color: "#ffffff",
                          borderRadius: "20px",
                          position: "absolute",
                          zIndex: 1,
                          top: "50%",
                          left: "0px",
                          opacity: 0.7,
                          transition: "opacity 0.3s",
                          "&:hover": {
                            backgroundColor: "#231f20",
                            color: "#ffffff",
                            opacity: 1,
                          },
                        }}
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                    )
                  }
                  onChange={(index) => {
                    if (
                      index ===
                      journeys[activeJourney - 1]?.links.length - 1
                    ) {
                      setDisableStakeContinueButton(false);
                    }
                  }}
                >
                  {journeys[activeJourney - 1]?.links.map((link: string) => {
                    return (
                      <Zoom>
                        <img src={link} />
                      </Zoom>
                    );
                  })}
                </Carousel>
              )}
            </div>
          ) : (
            isModalOpen && (
              <iframe
                width="560"
                height="315"
                src={`${journeys[activeJourney - 1]?.link}`}
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )
          ))}
      </div>
      <div className="journey-btn-group">
        <div
          className="journey-btn btn-cancel btn-slice-left"
          onClick={handleCancel}
        >
          Back
        </div>

        <button
          className="journey-btn btn-slice-right"
          onClick={handlePuzzleModal}
          disabled={(activeJourney && journeys[activeJourney - 1]?.mediaType === "carousel") ? disableStakeContinueButton: false}
          >
          Continue
        </button>
      </div>
    </Modal>
  );
};
