import {
    TokenData,
} from "src/services/api";
  
export const getByID = (arr: TokenData[], id: number) => {
    return arr.find((el: { id: number }) => el.id === id);
  };
  
  // Get all the ids which is to be staked (currently unstaked but is in the selected arr)
  export const getToBeStakedIds = (arr: TokenData[], idArr: number[]) => {
    return idArr.filter((el) => {
      const tokenData = getByID(arr, el);
      return !tokenData?.staked;
    });
  };
  
  export const getToBeUnstakedIds = (arr: TokenData[], idArr: number[]) => {
    return idArr.filter((el) => {
      const tokenData = getByID(arr, el);
      return tokenData?.staked;
    });
  };
  
  export const getAlreadyStakedIds = (arr: TokenData[]) => {
    return arr.filter((el) => el.staked);
  };
  
  export const getCount = (arr: TokenData[], idArr: number[]) => {
    const n1 = getToBeStakedIds(arr, idArr).length;
    const n2 = getAlreadyStakedIds(arr).length;
    const n3 = getToBeUnstakedIds(arr, idArr).length;
    const count = n1 + n2 - n3;
  
    // console.log({ n1, n2, n3 });
    return count;
};
  
  
export const getCountCooltopia = (arr: TokenData[], idArr: number[], filteredArr: TokenData[]) => {
  const n1 = getToBeStakedIds(arr, idArr).length;
  const n2 = getAlreadyStakedIds(filteredArr).length;
  const n3 = getToBeUnstakedIds(arr, idArr).length;
  const count = n1 + n2 - n3;

  // console.log({ n1, n2, n3 });
  return count;
};
  
  export const getStakedData = (arr: TokenData[], idArr: number[]) => {
    return arr.filter((el) => {
      return el?.staked && idArr.includes(el.id);
    });
  };
  
  export const getUnstakedData = (arr: TokenData[], idArr: number[]) => {
    return arr.filter((el) => {
      return !el?.staked && idArr.includes(el.id);
    });
  };
  
  export const getRandom = (items: any) => {
    // return items[Math.floor(Math.random() * items.length)];
    return items[0];
  };
  