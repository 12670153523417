import { Col, List, Modal, Row } from "antd";
import { useEffect, useState, useContext } from "react";
import { imageAPIURL } from "src/constants";
import { TokenData, TokenType } from "src/services/api";
import styled from "styled-components";
import { ScoreContext } from "src/hoc/ScoreProvider";

import { HOMEPAGE } from "../../../constants/images";

function ImageWithPlaceholder(props: any) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.src = props.src;
    image.onload = () => {
      setImageSrc(props.src);
    };
  }, [props.src]);

  return (
    <img
      src={imageSrc || HOMEPAGE.NFTPLACEHOLDER}
      alt="img"
      className={props.className}
      onClick={props.onClick}
      style={{
        borderRadius: "25px",
        cursor: "pointer",
        width: "150px",
        height: "150px",
      }}
    />
  );
}

interface Props {
  open: boolean;
  onCancel: () => void;
  selected: number[];
  setSelected: (val: number[]) => void;
  data: TokenData[];
  mobile?: boolean;
  cooltopia?: boolean;
  type?: any;
}

const getQueryType = (type: TokenType) => {
  return type.slice(0, -1);
};

export const StakeSelection: React.FC<Props> = ({
  selected,
  setSelected,
  open,
  onCancel,
  // data: tokens,
  mobile = false,
  cooltopia,
  type
}) => {
  // const [selected, setSelected] = useState<number[]>([]);
  const [tokens, setTokens] = useState<TokenData[]>([])

  const {
    catsData,
    petsData,
    cooltopiaData,
    wolvesData,
  } = useContext(ScoreContext);

  useEffect(() => {
    if(type){
      if(type === TokenType.Cats){
        setTokens(catsData)
      } else if(type === TokenType.Pets) {
        setTokens(petsData)
      }else if(type === TokenType.Cooltopia) {
        setTokens(cooltopiaData)
      }else if(type === TokenType.Wolves) {
        console.log("wolvesData", wolvesData)
        setTokens([...wolvesData])
      }
    }
  }, [type, catsData, petsData, cooltopiaData, wolvesData])

  const handleClick = (item: TokenData) => {
    //Cooltopia condition added to allow user to select only one NFT to stake
    if (item.type === TokenType.Cooltopia) {
      if (!item.staked) {
        setSelected([
          item.id,
          ...tokens.filter((el) => el.staked).map((el) => el.id),
        ]);
      } else {
        if (selected.includes(item.id)) {
          setSelected([]);
        } else {
          setSelected([item.id]);
        }
      }
    } else {
      if (selected.includes(item.id)) {
        setSelected(selected.filter((el) => el !== item.id));
      } else {
        setSelected([...selected, item.id]);
      }
    }
  };

  const allSelected = () =>
    selected.length === tokens.filter((el) => !el.staked).length;

  const handleSelectAll = () => {
    if (allSelected()) {
      setSelected(tokens.filter((el) => el.staked).map((el) => el.id));
    } else {
      setSelected(tokens.filter((el) => !el.staked).map((el) => el.id));
    }
  };

  const getClass = (item: TokenData, selected: number[]) => {
    console.log("selected", selected);
    if (item.staked) {
      if (selected.includes(item.id)) return "stake-image";
      else return "stake-image-selected";
    } else {
      if (selected.includes(item.id)) return "stake-image-selected";
      else return "stake-image";
    }
  };

  useEffect(() => {
    console.log("tokens", tokens)
  }, [tokens])

  return !mobile ? (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      className="modal-container"
      width={900}
    >
      <HeaderWrapper>
        <div
          className="container"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "480px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            // padding: "22px",
            // paddingTop: "60px",
            justifyContent: "center",
          }}
        >
          {tokens && (tokens.length === 0 ? <p className="empty-text">Nothing found</p> :
            <Col style={{ height: "inherit" }}>
              <Row gutter={[16, 16]} justify="space-around" align="top">
                {tokens.map((item, index) => (
                  <Col
                    key={index}
                    span={tokens.length >= 4 ? 6 : 24 / tokens.length}
                  >
                    {/* <ImageWithPlaceholder
                    src={`https://avatar.coolcatsnft.com/${getQueryType(
                      item.type
                    )}/${item.id}.png`}
                    className={getClass(item, selected)}
                    onClick={() => handleClick(item.id)}
                  /> */}
                    <ImageWithPlaceholder
                      src={
                        item.type === TokenType.Cooltopia
                          ? `https://metadata.coolcatsnft.com/item/image/${Math.floor(
                            item.id / 1000
                          )}${Math.floor(item.id / 1000) === 77 ? ".png" : ".gif"
                          }`
                          : item.type === TokenType.Wolves
                            ? `https://avatar.coolcatsnft.com/shadowwolf/${item.id}.png`
                            : `https://avatar.coolcatsnft.com/${getQueryType(
                              item.type
                            )}/${item.id}.png`
                      }
                      className={getClass(item, selected)}
                      onClick={() => handleClick(item)}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </div>

        <div className="selected-number">{selected.length} changed</div>

        <div className="select-modal-journey-btn-group">
          {!cooltopia && (
            <div className="journey-btn btn-outline" onClick={handleSelectAll}>
              {allSelected() ? "Deselect all" : "Select all"}
            </div>
          )}
          <div className="journey-btn" onClick={onCancel}>
            Continue
          </div>
        </div>
      </HeaderWrapper>
    </Modal>
  ) : (
    <HeaderWrapper>
      <div
        className="container"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "480px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          // padding: "22px",
          // paddingTop: "60px",
          justifyContent: "center",
        }}
      >
        {tokens && (tokens.length === 0 ? <p className="empty-text">Nothing found</p> :
          <Col style={{ height: "inherit" }}>
            <Row gutter={[16, 16]} justify="space-around" align="top">
              {tokens.map((item, index) => (
                <Col
                  key={index}
                  span={tokens.length >= 4 ? 12 : 24 / tokens.length}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ImageWithPlaceholder
                    src={
                      item.type === TokenType.Cooltopia
                        ? `https://metadata.coolcatsnft.com/item/image/${Math.floor(
                          item.id / 1000
                        )}${Math.floor(item.id / 1000) === 77 ? ".png" : ".gif"
                        }`
                        : item.type === TokenType.Wolves
                          ? `https://avatar.coolcatsnft.com/shadowwolf/${item.id}.png`
                          : `https://avatar.coolcatsnft.com/${getQueryType(
                            item.type
                          )}/${item.id}.png`
                    }
                    className={getClass(item, selected)}
                    onClick={() => handleClick(item)}
                  />
                  {/* <img
                  src={
                    item.type === TokenType.Fractures
                      ? `${imageAPIURL}?id=${item.id}&type=${getQueryType(
                          item.type
                        )}`
                      : `https://s3.amazonaws.com/metadata.coolcatsnft.com/${getQueryType(
                          item.type
                        )}/image/${item.id}.png`
                  }
                  className={getClass(item, selected)}
                 
                  onClick={() => handleClick(item.id)}
                /> */}
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </div>

      <div className="selected-number mob-selected-number">
        {selected.length} changed
      </div>

      <div className="select-modal-journey-btn-group mob-select-modal-journey-btn-group">
        {!cooltopia && (
          <div
            className="journey-btn btn-outline mob-btn-outline"
            onClick={handleSelectAll}
          >
            {allSelected() ? "Deselect all" : "Select all"}
          </div>
        )}
        <div className="journey-btn" onClick={onCancel}>
          Continue
        </div>
      </div>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  .selected-number {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    text-align: center;
    margin: 10px 0px;
    color: #231f20;
    justify-content: center;
  }

  .mob-selected-number {
    color: #ffffff;
  }

  .empty-text {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    text-align: center;
    margin: 10px 0px;
    color: #231f20;
  }

  .mob-empty-text {
    color: #231f20;
  }

  .container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* border-radius: 10px; */
    background-color: #555;
  }

  .container::-webkit-scrollbar {
    width: 8px;
    background-color: #555;
  }

  .container::-webkit-scrollbar-thumb {
    /* border-radius: 10px; */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #999999;
  }

  .journey-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .select-modal-journey-btn-group {
    justify-content: center;
  }

  .mob-select-modal-journey-btn-group {
    flex-direction: column;
  }

  .stake-image-selected {
    border: 4px solid #ffffff;
    border-radius: 25px;
  }

  .stake-image {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .mob-btn-outline {
    color: #ffffff;
    border: 3px solid #ffffff;
    background: transparent;
  }
`;
