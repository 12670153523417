import { Col, List, Modal, Row } from "antd";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import styled from "styled-components";
import {
  HOMEPAGE,
  ACHIEVEMENT,
  MINTREWARD,
  HEADER,
} from "src/constants/images";
import { imageAPIURL } from "src/constants";
import ConfettiExplosion from "react-confetti-explosion";
import Tada from "react-reveal/Tada";
import merkelProof from "../../../constants/merkel-tree";
import { useAccount } from "wagmi";
import { journey16RewardList as reward } from "src/constants/journey16-rewards";

function ImageWithPlaceholder(props: any) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.src = props.src;
    image.onload = () => {
      setImageSrc(props.src);
    };
  }, [props.src]);

  return (
    <img
      src={imageSrc || HOMEPAGE.NFTPLACEHOLDER}
      alt="img"
      className={props.className}
      onClick={props.onClick}
      style={{
        width: "175px",
        margin: "auto",
        display: "flex",
      }}
    />
  );
}

interface Props {
  open: boolean;
  onCancel: (state: boolean) => void;
  setHasMinted: (state: boolean) => void;
  isStaking: boolean;
  isShadow: boolean;
  isDisabled: boolean;
  data: any[];
  mobile?: boolean;
  journeyTitle: string;
  hasMinted: boolean;
}

export const RewardModal: React.FC<Props> = ({
  open,
  onCancel,
  isStaking,
  isDisabled,
  data,
  mobile = false,
  journeyTitle,
  hasMinted,
  setHasMinted,
  isShadow,
}) => {
  const { isConnected, address } = useAccount();

  return !mobile ? (
    <Modal
      centered
      open={open}
      onCancel={() => onCancel(true)}
      footer={null}
      className={`modal-container ${isShadow ? "shadow-modal-container" : ""}`}
      width={1000}
    >
      {isShadow && (
        <div className="modal-icon">
          <img src={HEADER.SHADOWLOGO} width="100px" />
        </div>
      )}
      <div className={`modal-title ${isShadow ? "shadow-modal-title" : ""}`}>
        {journeyTitle}
      </div>

      <HeaderWrapper>
        <div className={`confirm-header ${isShadow ? "text-white" : ""}`}>
          JOURNEY REWARDS
        </div>
        <div
          className="container"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
            display: "flex",
            alignItems: "center",
            // padding: "22px",
            // paddingTop: "60px",
            justifyContent: "center",
          }}
        >
          <Col style={{ height: "inherit" }}>
            {reward &&
              reward[address?.toLowerCase() as keyof typeof reward] && (
                <div
                  className={`confirm-description ${
                    isShadow ? "text-white" : ""
                  }`}
                >
                  {isShadow
                    ? "Umbrium is a mighty stone that channels the untamed forces of the shadows and provides a gateway to transformative possibilities. Please continue the journeys to continue collecting these important gemstones."
                    : "Aurorium seems to possess powers that are beyond anything we’ve seen before. Please continue the journeys to continue collecting these and other critical pieces!"}
                  <br />
                  <br />
                  You received the following in your wallet
                </div>
              )}
            {/* <Row gutter={[32, 32]} justify="space-around" align="top">
              {data.length !== 0 ?  data.map((item, index) => (
                <Col key={index} span={data.length >= 4 ? 6 : 24 / data.length}>
                  <ImageWithPlaceholder
                    src={`${imageAPIURL}?id=${item["token_id"]}&type=library/fractures&_vercel_no_cache=1`}
                  />
                  <div className="confirm-description2">
                    Fracture <br />#{item["token_id"]}
                  </div>
                </Col>
              )) : <p className="empty-text">Nothing found</p>}
            </Row> */}
            {/* <Row gutter={[16, 16]} justify="space-around" align="top">
            {Object.values(MINTREWARD).map((reward, index) => {
              return (
                <Col span={10} key={index}>
                  <ImageWithPlaceholder src={reward} />
                </Col>
              );
            })}
            </Row> */}
            {/* <Box display="flex" gap={4} flexWrap="wrap" justifyContent="center">
            {Object.values(MINTREWARD).map((reward, index) => {
              return (
                <Box key={index}>
                  <ImageWithPlaceholder src={reward} />
                </Box>
              );
            })}
            </Box> */}
            <Row gutter={[32, 32]} justify="space-around" align="top">
              {reward &&
              reward[address?.toLowerCase() as keyof typeof reward] ? (
                <Col>
                  <ImageWithPlaceholder
                    src={
                      MINTREWARD[
                        `REWARD${
                          reward[address?.toLowerCase() as keyof typeof reward]
                        }` as keyof typeof MINTREWARD
                      ]
                    }
                    // src={MINTREWARD['REWARD2']}
                  />
                </Col>
              ) : (
                <p className={`empty-text ${isShadow ? "text-white" : ""}`}>
                  Nothing found
                </p>
              )}
            </Row>
          </Col>
        </div>
        <div className={`footer-question ${isShadow ? "text-white" : ""}`}>
          Do you wish to continue your Journey??
        </div>
        {/* {data.length !== 0 ? (
          <>
            <div className="confirm-description">
              It looks like your fracture has evolved....
            </div>
            {revealReward ? (
              <Tada>
                <ImageWithPlaceholder
                  src={`${imageAPIURL}?id=${
                    data[activeReward] && data[activeReward]["token_id"]
                  }&type=library/fractures&_vercel_no_cache=1`}
                />
              </Tada>
            ) : (
              <ImageWithPlaceholder
                src={`https://static.vecteezy.com/system/resources/previews/007/126/739/original/question-mark-icon-free-vector.jpg`}
              />
            )}
          </>
        ) : (
          <p className="empty-text">No Rewards Found</p>
        )} */}
        <div className="journey-confirm-btn-group">
          <button
            className="journey-btn confirm-journey-btn cancel-btn"
            onClick={() => {
              onCancel(false);
              setHasMinted(false);
            }}
            disabled={isStaking || isDisabled}
          >
            No Thanks
          </button>
          <button
            className="journey-btn confirm-journey-btn"
            disabled={isStaking || isDisabled}
            onClick={() => {
              onCancel(true);
              setHasMinted(false);
            }}
          >
            Let's Go
          </button>
        </div>
      </HeaderWrapper>
    </Modal>
  ) : (
    <HeaderWrapper style={{ transform: "scale(0.79)" }}>
      <div className="confirm-header">JOURNEY REWARDS</div>
      <div
        className="container"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
          display: "flex",
          alignItems: "center",
          // padding: "22px",
          // paddingTop: "60px",
          justifyContent: "center",
        }}
      >
        {/* {data.length === 0 && <p className="empty-text">Nothing found</p>}
        <Col style={{ height: "inherit" }}>
          <Row gutter={[16, 16]} justify="space-around" align="top">
            {data.map((item, index) => (
              <Col key={index} span={data.length >= 4 ? 12 : 24 / data.length}>
                <ImageWithPlaceholder
                  src={`${imageAPIURL}?id=${item["token_id"]}&type=library/fractures&_vercel_no_cache=1`}
                />
                <div className="confirm-description2">
                  Fracture <br />#{item["token_id"]}
                </div>
              </Col>
            ))}
          </Row>
        </Col> */}
        <Col style={{ height: "inherit" }}>
          {reward && reward[address?.toLowerCase() as keyof typeof reward] && (
            <div className="confirm-description">
              Umbrium is a mighty stone that channels the untamed forces of the
              shadows and provides a gateway to transformative possibilities.
              Please continue the journeys to continue collecting these
              important gemstones.
              <br />
              <br />
              You received the following in your wallet
            </div>
          )}
          {/* <Row gutter={[16, 16]} justify="space-around" align="top">
            {Object.values(MINTREWARD).map((reward) => {
              return (
                <Col span={12}>
                  <ImageWithPlaceholder src={reward} />
                </Col>
              );
            })}
          </Row> */}
          <Row gutter={[32, 32]} justify="space-around" align="top">
            {reward && reward[address?.toLowerCase() as keyof typeof reward] ? (
              <Col>
                <ImageWithPlaceholder
                  src={
                    MINTREWARD[
                      `REWARD${
                        reward[address?.toLowerCase() as keyof typeof reward]
                      }` as keyof typeof MINTREWARD
                    ]
                  }
                  // src={MINTREWARD['REWARD2']}
                />
              </Col>
            ) : (
              <p className="empty-text">Nothing found</p>
            )}
          </Row>
        </Col>
      </div>
      {/* {data.length !== 0 ? (
          <>
            <div className="confirm-description">
              It looks like your fracture has evolved....
            </div>
            {revealReward ? (
              <Tada>
                <ImageWithPlaceholder
                  src={`${imageAPIURL}?id=${
                    data[activeReward] && data[activeReward]["token_id"]
                  }&type=library/fractures&_vercel_no_cache=1`}
                />
              </Tada>
            ) : (
              <ImageWithPlaceholder
                src={`https://static.vecteezy.com/system/resources/previews/007/126/739/original/question-mark-icon-free-vector.jpg`}
              />
            )}
          </>
        ) : (
          <p className="empty-text">No Rewards Found</p>
        )} */}
      <div className="journey-confirm-btn-group">
        <button
          className="journey-btn confirm-journey-btn cancel-btn"
          style={{ background: "#eb515d" }}
          onClick={() => onCancel(false)}
          disabled={isStaking || isDisabled}
        >
          No Thanks
        </button>
        <button
          className="journey-btn confirm-journey-btn"
          disabled={isStaking || isDisabled}
          onClick={() => onCancel(true)}
        >
          Let's Go
        </button>
      </div>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  .journey-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .confirm-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 12px;
    color: #231f20;
  }

  .empty-text {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    text-align: center;
    margin: 30px 0px;
    color: #231f20;
    justify-content: center;
  }

  .confirm-description {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 220;
    font-size: 20px;
    line-height: 140%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #231f20;

    margin-bottom: 28px;
  }

  .footer-question {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 220;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #231f20;
    margin-top: 40px;
  }

  .confirm-description2 {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #231f20;
    margin-top: 20px;
  }

  .confirm-warning {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 18px;
    color: #6f6f6f;
  }

  .journey-confirm-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    border-radius: 10px;
  }

  .confirm-journey-btn {
    background-color: #0094e3;
    color: #ffffff;
    border: none;
  }

  .cancel-btn {
    background-color: #eb515d;
  }
`;
