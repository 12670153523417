import React from "react";
import Countdown from "react-countdown";
import { useAccount, useSignMessage } from "wagmi";
import moment from "moment-timezone";
import { Spin } from "antd";
import { formattedDate } from "src/helpers/formatters";
import { useWeb3Modal } from "@web3modal/react";
import Button from "../../atoms/Button";
import { isJourneyActive, isJourneyCompleted, getCountdownDate } from "src/helpers/journey";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import CircularProgress from '@mui/material/CircularProgress';
import {HEADER, HOMEPAGE} from '../../../constants/images'

interface Props {
  loading: boolean;
  setIsLoading: (state: boolean) => void;
  journey: {
    startDate: string;
    stakingCloseDate: string;
    endDate: string;
    title: string;
    number: number;
    type: string;
  };
  journeyParticipated: boolean;
  journeyEnded: boolean;
  showModal: (journeyNumber: number) => void;
  setIsCompleteModalOpen: (state: boolean) => void;
  sidebar?: boolean;
  activeJourney: number;
  zoom?: number;
}

const JourneyCard: React.FC<Props> = ({
  loading,
  setIsLoading,
  journey,
  journeyParticipated,
  journeyEnded,
  showModal,
  setIsCompleteModalOpen,
  sidebar,
  activeJourney,
  zoom
}) => {
  const { startDate, stakingCloseDate, endDate } = journey;
  const { isConnected } = useAccount();
  const { text: countdownText, date: countdownDate } = getCountdownDate(
    startDate,
    stakingCloseDate,
    endDate
  );

  //Connect Modal
  const { open } = useWeb3Modal();

  async function onOpen() {
    setIsLoading(true);
    await open();
    setIsLoading(false);
  }

  return (
    <div className={`${sidebar ? "sidebar-journey-info" : "journey-info"}`} style={{ position: "relative" }}>
      {zoom && <div>{zoom}</div>}
      {!sidebar &&
        <div className={`icon-box ${journey.type === "normal" ? "coolcats-icon-box" : ""}`}>
          <img src={journey.type === "normal" ? HEADER.LOGO : HEADER.SHADOWLOGO} width={40} />
        </div>
      }
      <div className="journey-info-text">
        <div className="journey-title">{journey.title}</div>
        <div className="journey-ends">Journey Length: 2 Weeks</div>
        <div className="journey-countdown">
          {countdownText}
          {!isJourneyCompleted(endDate) &&" : "}
          {!isJourneyCompleted(endDate) && (
            <Countdown
              daysInHours={true}
              zeroPadTime={2}
              date={countdownDate}
            />
          )}
        </div>
      </div>

      {isConnected ? (
        activeJourney === journey.number &&
        (isJourneyActive(startDate, stakingCloseDate) && (
          <button
            className={`journey-btn ${journeyParticipated ? "joined-btn" : ""}`}
            onClick={() => showModal(journey.number)}
            disabled={
              loading
            }
          >
            {loading ? (
              <CircularProgress size={28} color="inherit" />
            ) : journeyParticipated ? (
              "You've Joined"
            ) : (
              "Join Journey"
            )}
          </button>
        ))
      ) : isJourneyActive(startDate, endDate) && (
        <div className="connect-btn contained-btn">
        <ConnectButton label="Connect" accountStatus="address" showBalance={false}  /> </div>
        // <Button
        //   btntext={loading ? "Loading..." : "Connect"}
        //   className="journey-btn joined-btn"
        //   onClick={() => {
        //     onOpen && onOpen();
        //   }}
        //   btntype="neutral"
        //   btnSize="very-small"
        // />
      )}
    </div>
  );
};

export default JourneyCard;
