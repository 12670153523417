import { Col, List, Modal, Row } from "antd";
import { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { generateCrowdsaleContract } from "../../../services/crowdSaleContract";
import { Card, notification, Spin } from "antd";
import { formatMetamaskError, formatWei } from "../../../helpers/formatters";
import { useAccount } from "wagmi";
import merkelProof from "../../../constants/merkel-tree";
import { MINTREWARD, HOMEPAGE, HEADER } from "src/constants/images";

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isStaking: boolean;
  isShadow: boolean;
  isDisabled: boolean;
  journeyTitle: string;
  completeMessage: string;
  isWhitelisted: boolean;
  setTxnHash: (state: string) => void;
  setHasMinted: (state: boolean) => void;
}

function ImageWithPlaceholder(props: any) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.src = props.src;
    image.onload = () => {
      setImageSrc(props.src);
    };
  }, [props.src]);

  return (
    <img
      src={imageSrc || HOMEPAGE.NFTPLACEHOLDER}
      alt="img"
      className={props.className}
      onClick={props.onClick}
      style={{
        width: "200px",
        margin: "auto",
        display: "flex",
      }}
    />
  );
}

export const CompleteModal: React.FC<Props> = ({
  onSubmit,
  open,
  onCancel,
  isStaking,
  isDisabled,
  journeyTitle,
  completeMessage,
  isWhitelisted,
  setTxnHash,
  setHasMinted,
  isShadow,
}) => {
  const { isConnected, address } = useAccount();
  const [isMintLoading, setIsMintLoading] = useState(false);

  const mintContract = useMemo(() => {
    return generateCrowdsaleContract();
  }, []);

  const mint = async () => {
    if (!isConnected) {
      notification.error({
        message: "Please connect with your wallet first!",
      });
      return;
    }

    setIsMintLoading(true);
    try {
      if (address) {
        const txn = await mintContract.allowlistMint(
          merkelProof[address as keyof typeof merkelProof].tokenId,
          address ?? "",
          merkelProof[address as keyof typeof merkelProof].proof
        );

        console.log("txn", txn.transactionHash);
        setTxnHash(txn.transactionHash);
        setHasMinted(true);
      }
      notification.success({ message: "Successfully Minted!" });
      // onSubmit();
    } catch (error) {
      const errorMessage = formatMetamaskError(error, "Error while minting"!);
      notification.error({ message: errorMessage });
    } finally {
      setIsMintLoading(false);
    }
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      footer={null}
      className={`modal-container ${isShadow ? "shadow-modal-container" : ""}`}
      width={900}
    >
      {isShadow && (
        <div className="modal-icon">
          <img src={HEADER.SHADOWLOGO} width="100px" />
        </div>
      )}
      <div className={`modal-title ${isShadow ? "shadow-modal-title" : ""}`}>
        {journeyTitle}
      </div>
      <HeaderWrapper>
        <div className={`confirm-header ${isShadow ? "text-white" : ""}`}>JOURNEY COMPLETE</div>
        <div
          className={`confirm-description ${isShadow ? "text-white" : ""}`}
          style={{ justifyContent: "center" }}
        >
          {completeMessage}
          <br />
          <br />
          {/* <em>"Let’s see what we’ve received for trusting him…"</em> */}
        </div>
        <div className="journey-confirm-btn-group">
          {isWhitelisted ? (
            <>
              <div
                className={`confirm-description2 ${isShadow ? "text-white" : ""}`}
                style={{ marginBottom: 20 }}
              >
                You've won the following reward, mint the reward to claim it.
              </div>
              <Row gutter={[32, 32]} justify="space-around" align="top">
                <Col>
                  <ImageWithPlaceholder
                    src={
                      MINTREWARD[
                        `REWARD${
                          merkelProof[address as keyof typeof merkelProof]
                            ?.tokenId
                        }` as keyof typeof MINTREWARD
                      ]
                    }
                    // src={MINTREWARD['REWARD2']}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <div className={`confirm-description2 ${isShadow ? "text-white" : ""}`}>
              Signing this message completes your journey and collects your
              rewards.
            </div>
          )}
          {isWhitelisted ? (
            <div className="mint-confirm-btn-group">
              <button
                className="journey-btn confirm-journey-btn cancel-btn"
                style={{ background: "#eb515d" }}
                onClick={onSubmit}
                disabled={isStaking || isDisabled}
              >
                No Thanks
              </button>
              <button
                className="journey-btn confirm-journey-btn"
                onClick={mint}
                disabled={isStaking || isDisabled}
              >
                Mint
              </button>
            </div>
          ) : (
            <button
              className="journey-btn confirm-journey-btn"
              onClick={onSubmit}
              disabled={isStaking || isDisabled}
            >
              Sign
            </button>
          )}
        </div>
        {!isWhitelisted && (
          <div className="confirm-warning">
            Note: Signature does not remove or transfer assets from your wallet
            and will not cost any gas to complete.
          </div>
        )}
      </HeaderWrapper>
    </Modal>
  );
};

const HeaderWrapper = styled.div`
  .journey-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .mint-confirm-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    border-radius: 10px;
  }

  .confirm-header {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 76px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 12px;
    color: #231f20;
  }

  .confirm-description {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: #231f20;

    margin-bottom: 28px;
  }

  .confirm-description2 {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #231f20;
  }

  .confirm-warning {
    font-family: "Herokid";
    font-style: normal;
    font-weight: 310;
    font-size: 16px;
    line-height: 159.4%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 18px;
    color: #6f6f6f;
  }

  .journey-confirm-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
  }

  .confirm-journey-btn {
    background-color: #0094e3;
    color: #ffffff;
    border: none;
  }
`;
